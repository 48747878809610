import moment from 'moment';
import $storage from '~/utils/storage.js';
import {
  STORAGE
} from '../utils/variables.c'

if (process.env.VUE_APP_TYPE === 'website') {
  const lang = $storage.get(STORAGE.LANG)

  lang === "en" ? moment.locale("en") : moment.locale("zh-cn");
} else {
  moment.locale("zh-cn");
}

function globalError(error) {
  console.error.call(console, error);
}

// 全局函数
export default {
  install(Vue) {
    Vue.prototype.$moment = moment;

    Vue.prototype.$error = globalError;
  }
}