<script>
  import {Table as ATable} from 'ant-design-vue';

  export default {
    name: 'NgTable',
    data() {
      return {
        tLoading: false,
        newParams: {},
        data: [],
        pagination: {},
      }
    },

    render() {
      const on = {
        ...this.$listeners,
        change: this.onChange
      }

      const props = {...this.$attrs, ...this.$props};

      const pagination = this.hasPagination ? this.pagination : false

      const table = (
        <a-table props={props} scopedSlots={this.$scopedSlots} on={on} loading={this.tLoading} pagination={pagination} data-source={this.data}>
        </a-table>
      )

      return (
        <div class="ng-table">
          {table}
        </div>
      )
    },

    created() {
      this.pagination.defaultCurrent = parseInt(this.$route.query.page || '1');
    },

    props: {
      request: Function,
      params: Object,
      load: Boolean,
      reload: Boolean,
      // 数据请求之后，转换数据
      transformData: Function,
      hasPagination: {
        type: Boolean,
        default: true
      }
    },

    watch: {
      params: {
        handler(newVal) {
          // 添加_reload参数，参数不进行合并
          let params = newVal._reload ? newVal : Object.assign(this.newParams, newVal);

          delete params._reload;

          this.newParams = params;

          if (params && params.page) {
            let page = parseInt(params.page);

            page && (this.pagination.current = page);
          }

          this.getData(this.newParams);
        },
        deep: true,
      },

      load(newVal) {
        if (newVal) {
          this.getData(this.newParams)

          setTimeout(() => {
            this.$emit('update:load', false);
          }, 100);
        }
      },

      reload(newVal) {
        if (newVal) {
          this.reloadData();

          this.$emit('update:reload', false);
        }
      }
    },

    components: {
      ATable
    },

    methods: {
      onChange(pagination) {
        this.newParams.page = this.togglePage(pagination);
        this.getData(this.newParams)
      },

      reloadData() {
        this.newParams.page = 1;

        this.pagination.current = 1;

        this.$root.$goSearch({
          page: 1
        })

        this.getData(this.newParams)
      },

      getData(params) {
        if (typeof this.request === 'function') {
          this.tLoading = true;

          this.request(params).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              typeof this.transformData === 'function' && (data = this.transformData(data));

              this.data = data.data.list;
              this.changePageInstance(data.data);
              this.$emit('dataChange', data);
            }

            this.tLoading = false;
          }).catch(() => {
            this.tLoading = false;
          })

        }
      },

      /**
       * 点击分页执行相关操作
       * 
       * @param {*} pagination 
       */
      togglePage(pagination) {
        const pager = {
          ...pagination
        };

        this.pagination = pager;

        this.$root.$goSearch({
          page: pagination.current
        })

        return pagination.current;
      },

      /**
       * 数据请求成功修改分页相关状态
       * 
       * @param {*} data 
       */
      changePageInstance(data) {
        let pagination = {
          ...this.pagination
        };

        if (data) {
          pagination.pageSize = data.page_size;
          pagination.total = data.total;
        }

        this.pagination = pagination;
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
