<template>
  <a-button
    v-bind="$props"
    class="send-code w-100"
    :class="{'ant-btn-loading': sending}"
    :loading="onLoading"
    @click="onClick"
  >{{!sending ? title : `${counter} s`}}</a-button>
  <!-- <a-button v-else v-bind="$props" class="send-code w-100 ant-btn-loading">{{`${counter} s`}}</a-button> -->
</template>

<script>
  import {Button} from 'ant-design-vue';

  import {SendCodeCountDown} from '~/config/config.c'

  let props = Object.assign({}, Button.props);

  delete props.open;

  // 倒计时开始时间
  const CounterSecond = SendCodeCountDown;

  export default {
    data() {
      return {
        sending: false,
        isFirst: true,
        counter: CounterSecond,
        onLoading: false,
      }
    },

    computed: {
      title() {
        return this.isFirst ? this.text : this.reText;
      }
    },

    props: {
      // param: Object, // 手机号、邮箱
      urlFunc: Function,
      text: {
        type: String,
        default: process.env.VUE_APP_TYPE === 'supplier_en' ? "Send verification code" : "发送验证码"
      },
      reText: {
        type: String,
        default: process.env.VUE_APP_TYPE === 'supplier_en' ? "Resend verification code" : "重新发送"
      },
      ...props
    },

    methods: {
      onClick() {
        if (this.sending) return;

        this.$emit('click', this.request);
      },

      request(param) {
        this.counter = CounterSecond;

        this.onLoading = true;

        typeof this.urlFunc === 'function' && this.urlFunc(param || {}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.startCountDown();
            this.isFirst = false;
          }

          this.$emit('sendSuccess', data);

          this.onLoading = false;
        }).catch(() => {
          this.onLoading = false;
        })
      },

      startCountDown() {
        this.sending = true;

        this.timer = setInterval(() => {
          this.counter--;

          if (this.counter <= 0) {
            clearInterval(this.timer);
            this.timer = null;
            this.sending = false;
            this.$emit('countDownFinished');
          }
        }, 1000);
      }
    }
  }
</script>

<style lang="scss" scoped>
  // .send-code {
  //   font-size: 14px;
  // }
</style>
