export default {
  loginTitle: '欢迎回到 Spark Music',
  registerTitle: '欢迎加入 Spark Music',

  // form_typeEmail: '请输入登录邮箱',
  // form_typePhone: '请输入手机号',
  // form_typePwd: '请输入密码',
  // form_typeUsername: '请输入用户名',
  // form_typeCode: '请输入请输入验证码',

  // form_validateEmailEmpty: '邮箱号码不可为空',
  // form_validatePhoneEmpty: '手机号码不可为空',
  // form_validatePwdEmpty: '密码不可为空',
  // form_validateUsernameEmpty: '用户名不可为空',
  // form_validateCodeEmpty: '验证码不可为空',

  req_loginSuccessTips: '登录成功',
  req_RegisterSuccessTips: '登录成功',
  req_findpwdSuccessTips: '找回密码成功',

  form_loginBtn: '登录',
  form_registerBtn: '注册',
  form_findPwdBtn: '确定',

  forgetPwd: '忘记密码',

  registerAndAgree: '注册即代表同意',
  registerNegociation: '《SparkMusic 用户协议》',

  loginWayOfEmail: '邮箱号码注册',
  loginWayOfPhone: '手机号码注册',
}