import form from './common/form';
import options from './common/options';
import response from './common/response';
import template from './common/template';
import music from './common/music';
import goods from './common/goods';

import * as Common from './common/common'

import allMusic from './pages/allMusic';
import cart from './pages/cart';
import authBook from './pages/authBook';
import tipPage from './pages/tipPage';
import supplier from './pages/supplier';
import login from './pages/login';
import order from './pages/order';

import userCollect from './user/userCollect';
import userShare from './user/userShare';
import userOrder from './user/userOrder';
import userTry from './user/userTry';
import userSet, {
  SettingMenu
} from './user/setting'

export default {
  form,
  goods,
  response,
  options,
  template,
  allMusic,
  cart,
  authBook,
  tipPage,
  music,
  ...Common,

  supplier,
  login,
  order,

  userCollect,
  userShare,
  userOrder,
  userTry,
  userSet,
  SettingMenu
}