export const SettingMenu = {
  title: '资料设置',

  basic: '基础信息',
  address: '收件信息',
  receipt: '发票抬头',
  company: '公司信息',
  account: '账户安全',
}

export default {
  ac_PhoneBind: '绑定手机号',
  ac_PhoneChange: '变更手机号',
  ac_EmailBind: '绑定邮箱',
  ac_EmailChange: '变更邮箱',

  ac_tipSendNote: '已发送短信验证码到绑定手机',
  ac_tipSendEmail: '已发送验证码到绑定邮箱',
  ac_tipBindSuccess: '绑定成功',
  ac_tipChangeSuccess: '修改成功',
  ac_verifyTitle: '身份验证',
  // 我们将向手机号xxxxx发送验证码
  ac_verifyContent: '为了你的帐户安全，我们将向{name} {number} 发送验证码，验证成功可进行下一步。',
  ac_noConnectEmailText: '未关联，关联后可通过邮箱号登录',
  ac_noConnectPhoneText: '未关联，关联后可通过手机号登录系统',
  ac_changePwd: '修改密码',
  ac_changePwdSub: '建议定期更改密码以保证账户安全',

  addr_title: '收件信息',
  addr_addAddrTitle: '添加收件信息',
  addr_setDefaultAddr: '设为默认地址',
  addr_tipEmpty: '暂无收件信息',
  addr_addrInfo: '地址信息',
  addr_confirmSetDefAddr: '确认设置该地址为默认地址信息吗？',
  addr_setSuccess: '设置成功',
  addr_delSuccess: '删除成功',
  addr_recevierName: '收件人姓名',
  addr_recevierPlaceholder: '请输入收件人真实姓名',
  addr_phone: '联系电话',
  add_phonePlaceholder: '请输入你的新手机号',
  addr_email: '联系邮箱',
  addr_emialPlaceholder: '请输入邮箱号码',
  addr_postalCode: '邮政编码',
  addr_postalCodePlaceholder: '请输入邮编号码',
  addr_receivAddr: '收件地址',
  addr_receivAddrPlaceholder: '请输入具体收件地址',
  addr_addTitle: '添加收件信息',
  addr_changeTitle: '修改收件信息',

  basic_title: '基本信息',
  basic_uploadHeaderTip: '大小 5M 以内，支持格式 .jpg .jpeg .png',
  basic_changeSuccess: '修改成功',

  rpt_title: '发票抬头',
  rpt_addTitle: '添加发票抬头',
  rpt_emptyTips: '暂无发票抬头信息{0}请根据开票需求，提交真实有效的抬头信息',
  rpt_setDefaultAddrRpt: '设为默认发票抬头',
  rpt_confirmSetDefault: '确认设置默认发票抬头吗？',
  rpt_setSuccess: '设置成功',
  rpt_delSuccess: '删除成功',
  rpt_type: '抬头类型',
  rpt_specTicket: '认证为专票资质',
  rpt_specTicketExtra: '应国家税务政策要求，开具企业抬头-增值税专用发票需要提交企业一般纳税人资质',
  rpt_setDefault: '设为默认抬头',
  rpt_submitPopConfirm: '编辑提交后，系统将重新审核专票抬头资质，是否确认提交？',
  rpt_saveAndVer: '保存并提交审核',
  rpt_taxPayerCode: '纳税人识别号',
  rpt_regPhone: '注册电话',
  rpt_regAddr: '注册地址',
  rpt_bank: '开户银行',
  rpt_bankAccount: '银行账号',
  rpt_taxerAuth: '一般纳税人资质',
  rpt_taxerAuthSub: '点击查看如何获得该资质认证凭证',
  rpt_name: '抬头名称',
}