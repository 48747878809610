import {
  Axios
} from '@/utils/from-common';

// 套餐列表
export const Package_List = (param) => Axios('POST', '/web/goods/list', param);

// 我的套餐下的音乐列表
export const Package_musicList = (param) => Axios('POST', '/web/usergoods/musicList', param);

// 我的套餐 下的套餐列表
export const Package_listInUser = (param) => Axios('POST', '/web/usergoods/list', param);

