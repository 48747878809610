// 头部菜单
export const headerMenu = {
  allMusic: '曲库',
  fine: '精选',
  buy: 'IP 音乐采购',
  order: '音乐定制',
  supplier: '音乐入驻',
  search: '音乐授权查询',
  loginText: '登录',
  registerText: '注册',
  vipMeal: '套餐',
  authorize: '授权介绍',
}

export const headerUserMenu = {
  order: '我的订单',
  collect: '我的收藏',
  download: '我的下载',
  share: '我的分享',
  setting: '资料设置',
  outlogin: '退出登录',
}

// 供应商类型
export const supplierType = {
  musician: '音乐人',
  team: '音乐团体',
  company: '音乐公司'
}

// 验证
export const validate = {
  required: '不可为空',
  uploadRequired: '请上传',
  checkRequired: '请选择',
  phone: '请输入正确格式的手机号',
  email: '邮箱格式必须含@',
  letter_zh_number: '只支持中英文及数字',
  letter_zh_number_char: '只支持键入汉字字母数字下划线空格',
  letter_number: '只支持英文字母及数字',
  pwd_len: '长度需要在8-20位',
  confirm_pass_content: '与首次键入的密码一致',
}

export const tooltip = {
  shareMusic: '分享音乐',
  collectMusicTip: '收藏音乐',
  cancelCollectMusicTip: '取消收藏',
  downloadMusic: '下载音乐',
  addToCart: '添加到购物车'
}

export const pay = {
  wx: '微信支付',
  zfb: '支付宝',
}

export const common = {
  all: '全部',
  del: '删除',
  confirm: '确定',
  preview: '预览',
  cancel: '取消',
  change: '变更',
  change1: '修改',
  edit: '编辑',
  connect: '关联',
  default: '默认',
  save: '保存',
  pass: '通过',
  reject: '拒绝',
  upload: '上传',
  download: '下载',
  add: '新增',
  submit: '提交',
  saveChange: '保存修改'
}

// 状态
export const status = {
  droped: '已下架',

  underReview: '审核中',
}

// 结果
export const result = {
  copySuccess: '复制成功',
  downloadSuccess: '下载成功',
  editSuccess: '编辑成功',
  addSuccess: '新增成功',
}