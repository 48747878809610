/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 基础信息
export const Base_info = (param) => Axios('POST', '/web/user/info', param);

// 修改基础信息
export const Base_editInfo = (param) => Axios('POST', '/web/user/saveInfo', param);



// 收件信息
export const Address_list = (param) => Axios('POST', '/web/express/list', param);

// 收件新增
export const Address_add = (param) => Axios('POST', '/web/express/add', param);

// 收件编辑
export const Address_edit = (param) => Axios('POST', '/web/express/save', param);

// 收件删除
export const Address_del = (param) => Axios('POST', '/web/express/del', param);

// 设置默认收货地址
export const Address_setDefault = (param) => Axios('POST', '/web/express/setDef', param);


// 公司信息
export const Company_list = (param) => Axios('POST', '/web/company/list', param);

// 公司新增
export const Company_add = (param) => Axios('POST', '/web/company/add', param);

// 公司编辑
export const Company_edit = (param) => Axios('POST', '/web/company/save', param);

// 公司删除
export const Company_del = (param) => Axios('POST', '/web/company/del', param);



// 账户信息
export const Account_info = (param) => Axios('POST', '/web/user/account', param);

// 绑定手机号
export const Account_bindPhone = (param) => Axios('POST', '/web/user/bindPhone', param);

// 编辑手机号
export const Account_editPhone = (param) => Axios('POST', '/web/user/savePhone', param);

// 绑定邮箱
export const Account_bindEmail = (param) => Axios('POST', '/web/user/bindEmail', param);

// 编辑邮箱
export const Account_editEmail = (param) => Axios('POST', '/web/user/saveEmail', param);

// 给当前账号发送手机/邮箱验证码
export const SendSelfCode = (param) => Axios('POST', '/web/user/sendAuth', param);

// 验证当前账号发送的手机/邮箱验证码
export const VerifySelfCode = (param) => Axios('POST', '/web/user/userAuth', param);



// 发票抬头列表
export const Recipt_list = (param) => Axios('POST', '/web/invoice/list', param);

// 添加发票抬头
export const Recipt_add = (param) => Axios('POST', '/web/invoice/add', param);

// 编辑发票抬头
export const Recipt_edit = (param) => Axios('POST', '/web/invoice/save', param);

// 删除发票抬头
export const Recipt_del = (param) => Axios('POST', '/web/invoice/del', param);

// 设置默认发票抬头
export const Recipt_setDefault = (param) => Axios('POST', '/web/invoice/setDef', param);



// 联系人信息
export const Contact_list = (param) => Axios('POST', '/scm/contact/list', param);

// 联系人新增
export const Contact_add = (param) => Axios('POST', '/scm/contact/create', param);

// 联系人编辑
export const Contact_edit = (param) => Axios('POST', '/scm/contact/update', param);

// 联系人删除
export const Contact_del = (param) => Axios('POST', '/scm/contact/delete', param);