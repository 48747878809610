<template>
  <div class="video-tag">
    <span v-if="play" class="icon" @click="onClick(false)">
      <slot name="playicon">
        <svg class="iconfont">
          <use xlink:href="#iconmusic-icon_pause" />
        </svg>
      </slot>
    </span>
    <span v-else class="icon" @click="onClick(true)">
      <slot name="pauseicon">
        <svg class="iconfont">
          <use xlink:href="#iconplay" />
        </svg>
      </slot>
    </span>
    <slot>
      <span class="text">{{text}}</span>
    </slot>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        play: false,
      }
    },

    props: {
      playing: {
        type: Boolean,
        default: false
      },
      text: String,
      url: String,
    },

    watch: {
      playing: {
        handler(newVal) {
          this.play = newVal;
        },
        immediate: true
      },
    },

    methods: {
      onClick(bool) {
        this.play = bool;
        this.$emit('play', bool);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .video-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 13px;
    border: 1px solid #dbdbdb;

    .icon {
      width: 20px;
      height: 20px;
      color: #fff;
      border-radius: 20px;
      background-color: $primary-color;
      cursor: pointer;

      > svg {
        width: 20px;
        height: 20px;
      }
    }

    .iconfont {
      fill: #fff;
    }

    .text {
      width: calc(100% - 30px);
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
    }
  }
</style>
