const MusicDetail = () => import('@/pages/musicDetail/musicDetail.vue');
const LabelDetail = () => import('@/pages/labels/LabelDetail.vue');

export default [{
    path: '/musicDetail/:music_id',
    name: 'musicDetail',
    component: MusicDetail
  },
  {
    path: '/labeldetail/:cate_id',
    name: 'LabelDetail',
    component: LabelDetail
  }
]