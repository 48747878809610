export default {
  name: '订单',
  title: '已成功提交订单，请尽快付款',
  codeText: '订单号：{number}，请在{time}分钟内完成支付，超时订单将自动关闭',
  goodsInfo: '商品信息',
  totalPrice: '合计：￥{num}',
  payImme: '立即支付',

  reqTipPayMethod: '请选择支付方式',

  labelOrderTime: '下单时间',
  labelPayPrice: '实付金额',
  cancelOrder: '取消订单',

  repay: '重新支付',
  reoder: '重新下单',
  copyOrder: '复制订单',
  activeAuth: '激活授权书',
  downloadAuth: '下载授权书',
  applyRecipt: '申请发票',
  applyContract: '申请合同'
}