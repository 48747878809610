<template>
  <div class="form-checkbox-all">
    <a-checkbox
      :checked="checkAll"
      :indeterminate="indeterminate"
      @change="onCheckAllChange($event.target.checked)"
      :disabled="disabled"
    >{{allLabel}}</a-checkbox>

    <br />

    <div class="child-item">
      <a-checkbox-group
        v-model="checkedList"
        :options="handledData"
        @change="onChange"
        :disabled="disabled"
      ></a-checkbox-group>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        indeterminate: false,
        checkedList: [],
        clickGroup: false,
      }
    },

    computed: {
      handledData() {
        let fieldNames = this.fieldNames;

        return !fieldNames ? this.data : this.toggleField(this.data, fieldNames);
      }
    },

    props: {
      allLabel: String,
      name: String,
      data: {
        type: Array,
        default() {
          return [];
        }
      },
      value: Array,
      checkAll: Boolean,
      fieldNames: Object,

      avoidTrigger: Boolean, // 为了避免父级赋值触发change事件
      disabled: Boolean
    },

    mounted() {
      let value = this.value;

      if (Array.isArray(value) && value.length > 0) {
        this.checkedList = value;
        this.onChange(value);
      }
    },

    watch: {
      checkAll: {
        handler(newVal) {
          if (!this.isClick) {
            this.onCheckAllChange(newVal);
          }

          this.isClick = false;
        },
        immediate: true
      },
    },

    methods: {

      toggleField(data, fieldNames) {

        return one(data, fieldNames);

        function one(data, fieldNames) {
          return data.map(item => {
            let newItem = {...item};

            Object.keys(fieldNames).forEach(cItem => {
              let nItem = fieldNames[cItem];

              if (nItem && typeof nItem === 'string' && nItem !== 'child') {
                typeof item[nItem] !== 'undefined' && (newItem[cItem] = item[nItem]);
                delete newItem[nItem];
              }
            })

            let child = item[fieldNames.child];

            if (child && child.length > 0) {
              newItem.child = one(child, fieldNames);
              delete newItem[fieldNames.child];
            }

            return newItem;
          })
        }
      },

      onCheckAllChange(checked) {
        let list = this.handledData;

        this.checkedList = checked ? list.map(item => item.value) : [];

        this.isClick = true;

        checked && (this.indeterminate = false);

        this.$emit('update:checkAll', checked);

        this.$emit('change', this.checkedList);
      },

      onChange(checkedList) {
        let list = this.handledData;

        this.isClick = true;

        this.indeterminate = !!checkedList.length && checkedList.length < list.length;

        let checkAll = checkedList.length === list.length;

        this.$emit('update:checkAll', checkAll);

        this.$emit('change', checkedList);
      },

    }
  }
</script>

<style lang="scss" scoped>
  .form-checkbox-all {
    .child-item {
      padding-left: 24px;
    }

    .ant-checkbox-group {
      line-height: 40px;
    }
  }
</style>
