<template>
  <a-radio-group v-bind="$props" @change="triggerChange">
    <template v-for="item in list">
      <a-radio :value="item[valueName]" :key="item[valueName]">{{item[labelName]}}</a-radio>
    </template>
  </a-radio-group>
</template>

<script>
  import {Radio} from 'ant-design-vue';
  import formMixin from './_formMixins';

  let props = Object.assign({}, Radio.Group.props);

  delete props.options;

  export default {
    name: 'FormRadioGroup',
    data() {
      return {
        list: [],
      }
    },

    mixins: [formMixin],

    props: {
      ...props,
      all: {
        type: Boolean,
        default: true
      },
      allName: {
        type: String,
        default: '全部'
      },
      allValue: {
        type: [String, Number],
        default: '0'
      },
      valueName: {
        type: String,
        default: 'value'
      },
      labelName: {
        type: String,
        default: 'label'
      },
      option: Array,
      dataFunc: Function,
      params: {
        type: Object,
        default() {
          return {}
        }
      },
      immediate: Boolean
    },

    watch: {
      params: {
        handler(newVal) {
          !this.option && this.getData(newVal)
        },
        immediate: true,
        deep: true
      },

      option: {
        handler(newVal) {
          if (newVal && newVal.length > 0) {
            let options = [...newVal];

            this.all && options.unshift({
              [this.labelName]: this.allName,
              [this.valueName]: this.allValue
            })

            this.list = options;
          }
        },
        immediate: true
      }
    },

    methods: {
      getData(param) {
        if (typeof this.dataFunc === 'function') {
          this.loading = true;

          this.dataFunc(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let options = data.data ? data.data.list : [];

              if (this.all) {
                options.unshift({
                  [this.labelName]: this.allName,
                  [this.valueName]: this.allValue
                })
              }

              this.list = options;
            }

            this.loading = false;
          }).catch(() => {
            this.loading = false;
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
