export default {
  cartTitle: '购物车',
  cartSubmit: '提交订单',
  cartMusicAmount: '共 {num} 首音乐',

  cartTableSelectNumTip: '已选中 {num} 件商品',
  cartTableTotalTip: '合计：￥{num}',

  reqMessageRead: '请阅读并同意音乐购买须知',
  reqMessageConfirmDelMusic: '确认要删除这首音乐吗？',
  resMessageDelMusicSuccess: '已成功删除该音乐',
  resMessageEditSuccess: '编辑成功',

  cartDialogTitle: '添加到购物车',
}