/**
 * 
 * @param {*} r 
 * @param {*} g 
 * @param {*} b 
 */
function rgbToHsl(r, g, b) {
  r = r / 255;
  g = g / 255;
  b = b / 255;

  var min = Math.min(r, g, b);
  var max = Math.max(r, g, b);
  var l = (min + max) / 2;
  var difference = max - min;
  var h, s;

  if (max == min) {
    h = 0;
    s = 0;
  } else {
    s = l > 0.5 ? difference / (2.0 - max - min) : difference / (max + min);
    switch (max) {
      case r:
        h = (g - b) / difference + (g < b ? 6 : 0);
        break;
      case g:
        h = 2.0 + (b - r) / difference;
        break;
      case b:
        h = 4.0 + (r - g) / difference;
        break;
    }
    h = Math.round(h * 60);
  }

  s = Math.round(s * 100); //转换成百分比的形式
  l = Math.round(l * 100);

  return [h, s, l];
}

/**
 * 
 * @param {*} h 
 * @param {*} s 
 * @param {*} l 
 */
function hslToRgb(h, s, l) {
  var rgb = [];

  h = h / 360;
  s = s / 100;
  l = l / 100;

  if (s == 0) {
    rgb = [Math.round(l * 255), Math.round(l * 255), Math.round(l * 255)];
  } else {
    var q = l >= 0.5 ? (l + s - l * s) : (l * (1 + s));
    var p = 2 * l - q;

    rgb[0] = h + 1 / 3;
    rgb[1] = h;
    rgb[2] = h - 1 / 3;

    for (var i = 0; i < rgb.length; i++) {
      var tc = rgb[i];

      if (tc < 0) {
        tc = tc + 1;
      } else if (tc > 1) {
        tc = tc - 1;
      }

      switch (true) {
        case (tc < (1 / 6)):
          tc = p + (q - p) * 6 * tc;
          break;
        case ((1 / 6) <= tc && tc < 0.5):
          tc = q;
          break;
        case (0.5 <= tc && tc < (2 / 3)):
          tc = p + (q - p) * (4 - 6 * tc);
          break;
        default:
          tc = p;
          break;
      }

      rgb[i] = Math.round(tc * 255);
    }
  }

  return rgb;
}

function toRgb(color) {
  if (typeof color !== 'string') {
    console.log('color must be a string');
    return [];
  }

  if (!/[0-9a-fA-F]{3}|[0-9a-fA-F]{3}/.test(color)) {
    console.log('unit amount of word color must be three or six');
    return [];
  }

  color = ~color.indexOf('#') ? color.slice(1) : color;

  if (color.length === 3) {
    color = color.split('').map(item => item.repeat(2));
  } else if (color.length === 6) {
    color = [...color.matchAll(/[0-9a-fA-F]{2}/g)]
  } else {
    color = [];
  }

  return color.map(item => parseInt(item[0], 16))
}



/**
 * @param {*} color 要转换的颜色
 * @param {*} level 加深的程度，限0-1之间
 */
export const lighten = function (color, level = 0.4) {
  if (typeof color !== 'string' || color.length === '') {
    console.error('color must be string');
    return '';
  }

  color = toRgb(color);

  color = rgbToHsl(...color)

  color[2] = color[2] + (100 - color[2]) * level;

  color = hslToRgb(...color)

  return `rgb(${color.join(',')})`;
}


/**
 * @param {*} color 要转换的颜色
 * @param {*} level 加深的程度，限0-1之间
 */
export const darken = function (color, level = 0.5) {
  if (typeof color !== 'string' || color.length === '') {
    console.error('color must be string');
    return '';
  }

  color = toRgb(color);

  color = rgbToHsl(...color)

  color[2] = color[2] * level;

  color = hslToRgb(...color)

  return `rgb(${color.join(',')})`;
}