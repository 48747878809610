import axios from 'axios';
// import {
//   Message,
// } from 'ant-design-vue';
import Vue from 'vue';
import * as $config from '@/config/config';
import $storage from '~/utils/storage'
import * as $var from '~/utils/variables.c'
import * as $func from '@/utils/function'

import $store from '@/store/store'
import $route from '@/router/router'
import { codeLang } from './codeLang'

let instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? $config.ServeBaseHost : '/',
  withCredentials: false, // 跨域请求时是否需要凭证
  timeout: 15 * 1000, // 请求超时时间
});

const codeMsg = process.env.VUE_APP_TYPE === 'supplier_en' ? codeLang.en : codeLang.zh_CN

/**
 * 处理请求参数
 * 将post 和 put 请求参数修改为类表单提交的数据格式
 *
 * @param {*} params 请求参数
 */
function handleRequestParams(params) {
  let ret = '';

  if (Object.prototype.toString.call(params) !== "[object FormData]" && typeof params === 'object') {
    params = $func.transferParamFromObjToStr(params, (text) => text !== undefined && text !== null ? text : '');

    Object.entries(params).forEach(([key, value]) => {
      ret += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })

    return ret.slice(1);
  }

  return params;
}

/**
 * 记录错误次数，保证同一时间弹出多次弹窗
 *
 */
let checkOutLoginNumber = 0;
// 防抖执行 清空计数
let debounceCb = $func.debounce(() => {
  checkOutLoginNumber = 0;
}, 1000);

/**
 * 处理响应错误
 *
 * @param {} response
 */
function handleResponse(response) {
  let data = response.data;

  // 服务器返回错误
  if (data && data.code && data.code.toString() !== '0') {
    let num = ++checkOutLoginNumber;

    debounceCb();

    // 登录已过期 ...
    if (data.code >= 2001 && data.code < 2006) {
      if (process.env.VUE_APP_TYPE === 'website' || process.env.VUE_APP_TYPE === 'website_new') {
        $store.dispatch('outLogin');
        $store.commit('changeLoginAndRegisterDialog', true);

        // let curRoute = $route.history.current;
        // let curPath = curRoute ? curRoute.fullPath : '';
        // let curRouteName = curRoute.name;

        // curRouteName !== 'login' && $route.replace(`/login?to=${curPath}`);
      } else {
        num <= 1 && $store.dispatch('checkOutLogin', {
          router: $route,
          content: data.msg
        });
      }
    }

    // num <= 1 && data.msg && Message.error(data.msg);
    num <= 1 && data.msg && Vue.prototype.$message.error(data.msg);
  }

  return response;
}

/**
 * 添加请求拦截器
 *
 * 响应 code 码
 *
 * >= 2000 公共提示
 * >= 3000 后台管理系统 每个接口错误提示
 * >= 4000 供应商系统 每个接口错误提示
 * >= 5000 官网 每个接口错误提示
 *
 * 2006 => 没有访问权限
 * 2005 => 登陆已过期，请重新登录 ( 登陆后，长时间没有调用任何接口，登陆会过期)
 * 2004 => 您的网络发生变化，请重新登录 (登陆时生成的ip，与当前请求接口的ip不一致)
 * 2003 => 用户不存在，请重新登录(token成功解析出用户id，但是数据库中查询不到这个用户)
 * 2002 => 登陆验证失败，请重新等  (传的token 格式不正确，无法解析)
 * 2001 => 您尚未登陆 (调用需要登陆的接口，但是token 为空)
 */
instance.interceptors.request.use(config => {
  let token = $storage.get($var.STORAGE.TOKEN);

  if (config.method === 'post' || config.method === 'put') {
    config.data = handleRequestParams(config.data);
  }

  config.headers.Token = token || '';
  if( process.env.VUE_APP_TYPE === 'supplier_en' ){
    config.headers.langversion = process.env.VUE_APP_TYPE === 'supplier_en' ? 'en' : 'zh'
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
  return handleResponse(response);
}, error => {

  if (error && error.response) {
    if (error.response.status) {
      const status = error.response.status.toString()
      error.message = codeMsg[status] ? codeMsg[status] : `${codeMsg['default']}${error.response.status}`
    }
  } else if (error.toString() !== 'Cancel') {
    error.message = codeMsg['error']
  }

  // error.message && Message.error(error.message);
  error.message && Vue.prototype.$message.error(error.message);

  return Promise.reject(error);
});

export default function (method, url, param, options) {
  switch (method.toLowerCase()) {
    case 'get':
      return instance.get(url, {
        params: param,
        ...options
      })

    case 'post':
      return instance.post(url, param, options)

    default:
      return instance;
  }
}
