<template>
  <div class="form-mark form-checkbox-group">
    <a-checkbox
      v-if="all"
      v-model="checkAll"
      :indeterminate="indeterminate"
      @change="onCheckAllChange($event.target.checked)"
      :disabled="disabled"
    >{{allLabel}}</a-checkbox>

    <template v-for="(item, index) in handledData">
      <div :class="all ? 'child-item' : ''" :key="item.id">
        <form-checkbox-all
          :data="item.child || []"
          :allLabel="item.label"
          :checkAll.sync="checkedGroup[`${keyName}${index}`]"
          :value="checkedList[index]"
          @change="onCheckboxChange(index, $event)"
          :fieldNames="fieldNames"
          :disabled="disabled"
        ></form-checkbox-all>
      </div>
    </template>
  </div>
</template>

<script>
  import {FormCheckboxAll} from '@/components/index'

  export default {
    data() {
      return {
        keyName: 'name',
        checkAll: false,
        checkedGroup: {},
        indeterminate: false,

        checkedList: {
          0: [],
          //....
        },
      }
    },

    props: {
      all: {
        type: Boolean,
        default: true
      },
      name: String,
      data: Array,
      allLabel: String,
      value: Array,
      /**
       * default 
       * {label: 'label', value: 'value'}
       */
      fieldNames: Object,
      disabled: Boolean
    },

    watch: {
      data: {
        handler(newVal) {
          this.init(newVal);
        },
        immediate: true
      },
    },

    computed: {
      handledData() {
        let fieldNames = this.fieldNames;

        return !fieldNames ? this.data : this.toggleField(this.data, fieldNames);
      }
    },

    mounted() {
      this.initDefault();
    },

    components: {
      FormCheckboxAll
    },

    methods: {

      toggleField(data, fieldNames) {
        const childName = 'child';

        return one(data, fieldNames);

        function one(data, fieldNames) {
          return data.map(item => {
            let newItem = {...item};

            Object.keys(fieldNames).forEach(cItem => {
              let nItem = fieldNames[cItem];

              if (nItem && typeof nItem === 'string' && nItem !== childName) {
                typeof item[nItem] !== 'undefined' && (newItem[cItem] = item[nItem]);
                delete newItem[nItem];
              }
            })

            let child = item[fieldNames[childName]];

            if (child && child.length > 0) {
              newItem[childName] = one(child, fieldNames);
              delete newItem[fieldNames[childName]];
            }

            return newItem;
          })
        }
      },

      initDefault() {
        let value = this.value || [];

        this.checkedList = this.handledData.map(item => {
          let child = item.child || [];

          return child.filter(cItem => {
            return value.includes(cItem.value)
          }).map(nItem => nItem.value)
        })
      },

      init(data) {
        let checkedGroup = {};
        let checkedList = {};

        data.forEach((item, index) => {
          checkedGroup[`${this.keyName}${index}`] = false;
          checkedList[index] = [];
        })

        this.checkedGroup = checkedGroup;
        this.checkedList = checkedList;

        this.$children.forEach(item => {
          item.data.isClick = true;
        })

        this.initDefault();
      },

      onCheckAllChange(checked) {
        Object.keys(this.checkedGroup).forEach(item => {
          this.checkedGroup[item] = checked;
        })

        checked && (this.indeterminate = false);
      },

      onCheckboxChange(index, checkedList) {
        this.checkedList[index] = checkedList || [];

        this.isCheckAll();

        let finalArr = Object.values(this.checkedList).reduce((a, b) => a.concat(b), []);

        this.$emit('change', finalArr);
      },

      isCheckAll() {
        let data = this.handledData;
        let list = this.checkedList;

        let listLens = Object.values(list).map(item => item.length || 0);
        let dataLens = data.map(item => item.child ? item.child.length : 0);

        let listFinal = listLens.reduce((a, b) => a + b, 0);
        let dataFinal = dataLens.reduce((a, b) => a + b, 0);

        this.indeterminate = !!listFinal && listFinal < dataFinal;
        this.checkAll = listFinal === dataFinal;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form-checkbox-group {
    .child-item {
      padding-left: 24px;
    }

    .ant-checkbox-group {
      line-height: 40px;
    }
  }
</style>
