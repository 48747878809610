  export default {
    data() {
      return {
        // show: false,
        dialogBodyMaxHeight: '',
      }
    },

    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },


    computed: {
      bodyStyle() {
        return {
          maxHeight: `${this.dialogBodyMaxHeight ? this.dialogBodyMaxHeight : '500'}px`,
          overflow: 'auto',
        }
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.form && typeof this.form.resetFields === 'function' && this.form.resetFields();

          this.$nextTick(() => {
            this.calcMaxHeight();
          })
        }

        this.show = newVal;
      },

      show(newVal) {
        if (!newVal) {
          this.$emit('update:visible', newVal)
        }
      }
    },

    // mounted() {},

    methods: {
      calcMaxHeight() {
        let bodyHeight = window.innerHeight ? window.innerHeight : document.body.clientHeight;
        let bodyEles = document.getElementsByClassName('ant-modal-body');
        let mulHeight = 300;

        if (bodyEles && bodyEles.length > 0) {
          let bodyEle = bodyEles[bodyEles.length - 1];
          let react = bodyEle.getBoundingClientRect && bodyEle.getBoundingClientRect();

          if (react && react.top) {
            mulHeight = react.top + 150;
          }
        }

        this.dialogBodyMaxHeight = bodyHeight - mulHeight;
      }
    }
  }