<template>
  <div
    class="ng-video"
    :class="hide ? 'down' : ''"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseout"
  >
    <div class="control-handle" @click="hide = !hide">
      <a-icon v-show="!hide" type="down" />
      <a-icon v-show="hide" type="up" />
    </div>

    <div class="fixed-handle" :class="[isFixed ? 'active' : '']" @click="onFixed">
      <a-icon type="pushpin" />
    </div>

    <audio
      :src="videoInfo.url"
      @play="onPlay"
      @pause="onPause"
      @ended="endVideo"
      @error="onError"
      @load="onLoad"
      ref="video"
      controls
    ></audio>

    <span class="toggle-tool">
      <a-button class="prev-btn" type="link" :disabled="disabledPrev" @click="goPrevMusic">
        <svg class="iconfont">
          <use xlink:href="#iconshangyishou" />
        </svg>
      </a-button>
      <a-button class="next-btn" type="link" :disabled="disabledNext" @click="goNextMusic">
        <svg class="iconfont">
          <use xlink:href="#iconshangyishou" />
        </svg>
      </a-button>
    </span>

    <span class="text ellipsis">{{videoInfo.name}}</span>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hide: true,

        timer: null,

        isFixed: false,

        timeSpace: 5 * 1000,
      }
    },

    computed: {
      videoInfo() {
        return this.$store.state.globalVideoInfo || {};
      },

      playing() {
        return !!this.$store.state.globalVideoPlaying;
      },

      videoList() {
        return this.$store.state.globalVideoList || [];
      },

      currentIndex() {
        return this.videoList.findIndex(item => item.url === this.videoInfo.url);
      },

      disabledPrev() {
        return !(this.currentIndex > 0 && this.currentIndex <= this.videoList.length - 1)
      },

      disabledNext() {
        return !(this.currentIndex >= 0 && this.currentIndex < this.videoList.length - 1)
      }
    },

    watch: {
      playing: {
        handler(newVal) {
          let method = newVal ? 'playVideo' : 'pauseVideo';

          this.$nextTick(() => {
            typeof this[method] === 'function' && this[method]();
          })
        },
        immediate: true
      },

      videoInfo: {
        handler(newVal) {
          if (newVal) {
            this.hide = false;
            !this.isFixed && this.transitionHide();
          } else {
            this.hide = true;
            clearTimeout(this.timer);
          }
        },
        deep: true,
        immediate: true
      },

      isFixed(newVal) {
        newVal && clearTimeout(this.timer);
      }
    },

    methods: {
      goPrevMusic() {
        this.toggleList(-1);
      },

      goNextMusic() {
        this.toggleList(1);
      },

      toggleList(step) {
        let list = this.videoList;
        let len = list.length;
        let cinx = this.currentIndex;

        cinx += step;

        cinx = (cinx < 0 ? 0 : cinx > len ? len - 1 : cinx);

        let videoInfo = list[cinx] || {};

        this.$store.commit('changeVideoInfo', videoInfo);

        setTimeout(() => {
          this.playVideo();
        }, 300);
      },

      onFixed() {
        this.isFixed = !this.isFixed;
      },

      onLoad() {
      },

      onMouseEnter() {
        this.inContent = true;

        clearTimeout(this.timer);
      },

      onMouseout() {
        this.inContent = false;

        !this.isFixed && this.transitionHide();
      },

      transitionHide() {
        clearTimeout(this.timer);

        if (!this.hide) {
          this.timer = setTimeout(() => {
            this.hide = true;
          }, this.timeSpace);
        }
      },

      playVideo() {
        let video = this.$refs.video;

        try {
          typeof video.play === 'function' && video.play();
        } catch (error) {
          this.onError(error);
        }
      },

      pauseVideo() {
        let video = this.$refs.video;

        video && typeof video.pause === 'function' && video.pause();
      },

      onPlay() {
        this.$store.commit('changeVideoPlaying', true);
      },

      onPause() {
        this.$store.commit('changeVideoPlaying', false);
      },

      endVideo() {
        this.$store.commit('changeVideoPlaying', false);
      },

      onError() {
        this.$store.commit('changeVideoPlaying', false);
        this.$message.error('音频数据加载失败，请重新加载');
        this.pauseVideo();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #efefef;
    padding: 15px 20px 10px;
    box-shadow: 2px 2px 5px #999, -2px -2px 5px #efefef;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: translateY(-67px);
    transition: transform linear 0.2s;

    .control-handle {
      position: absolute;
      right: 20px;
      top: 0;
      width: 42px;
      height: 25px;
      border: 1px solid #efefef;
      transform: translateY(-25px);
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      background: #e3e3e3;
      cursor: pointer;
    }

    .fixed-handle {
      position: absolute;
      top: 3px;
      right: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      cursor: pointer;

      &.active {
        background-color: rgba($color: #000000, $alpha: 0.1);
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .icon {
      cursor: pointer;
    }

    audio {
      height: 40px;
      // width: 100%;
    }

    &.down {
      transform: translateY(0);
    }

    .text {
      display: inline-block;
      width: 150px;
      margin-left: 20px;
    }

    .toggle-tool {
      padding: 0 10px;

      .iconfont {
        cursor: pointer;
        transition: color linear 0.2s;

        &:hover {
          opacity: 0.8;
        }
      }

      .next-btn {
        .iconfont {
          transform: rotate(180deg);
        }
      }

      /deep/ {
        .ant-btn {
          padding: 0 8px;
        }
      }
    }
  }
</style>
