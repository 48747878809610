export default {
  uploadFileLimit: '上传文件不能大于{size}',
  selectImage: '请选择图片',

  selectAgain: '请重新选择作品',

  selectCorrentFileType: '请上传正确的文件类型',

  uploading: '上传中',

  uploadingNoSubmit: '正在上传中，不能提交',
}