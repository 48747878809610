import {
  Axios
} from '@/utils/from-common';

// 优惠券列表
export const CouponList = (param) => Axios('POST', '/web/coupon/couponList', param);

// 兑换优惠券
export const CouponExchange = (param) => Axios('POST', '/web/coupon/exchange', param);

// [购物车]优惠券列表

export const GetCouponForCart = (param) => Axios('POST', '/web/coupon/getCouponForCart', param);
