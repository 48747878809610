<template>
  <a-modal
    class="license-preview"
    type="confirm"
    width="620px"
    :title="title"
    okText="关闭"
    v-model="show"
    :maskClosable="false"
    @ok="handleOk"
  >
    <a :href="url" target="_blank" class="img-content">
      <img :src="url" alt />
    </a>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
      }
    },

    mixins: [dialogMixins],

    props: {
      title: {
        type: String,
        default: '预览图片'
      },
      url: String
    },

    methods: {
      handleOk() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .license-preview {
    vertical-align: top;

    .img-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      > img {
        width: 100%;
      }
    }

    /deep/ .ant-modal-footer {
      .ant-btn:first-child {
        display: none;
      }
    }
  }
</style>
