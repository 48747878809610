const Subscribe = () => import('@/pages/subscribe/subscribe.vue');          //订阅信息
const Subscribe_v2 = () => import('@/pages/subscribe/subscribe_v2.vue');          //订阅信息
const Subscribe_introduce = () => import('@/pages/subscribe/subscribe_introduce.vue'); //订阅介绍
const Subscribe_introduce_v2 = () => import('@/pages/subscribe/subscribe_introduce_v2.vue'); //订阅介绍

const company_scope = () => import('@/pages/subscribe/company_scope.vue'); //企业授权范围
const personal_scope = () => import('@/pages/subscribe/personal_scope.vue'); //个人授权范围

export default [{
        path: '/subscribe',
        name: 'subscribe',
        component: Subscribe,
    },
    {
        path: '/subscribe_v2',
        name: 'subscribe_v2',
        component: Subscribe_v2,
    },
    {
        path: '/subscribe_introduce_v2',
        name: 'subscribe_introduce_v2',
        component: Subscribe_introduce_v2
    }, 
    {
        path: '/subscribe_introduce',
        name: 'subscribe_introduce',
        component: Subscribe_introduce
    },
    {
        path: '/company_scope/:licensed_id',
        name: 'company_scope',
        component: company_scope
    },
    {
        path: '/personal_scope/:licensed_id',
        name: 'personal_scope',
        component: personal_scope
    },
]