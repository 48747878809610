<template>
  <div class="ng-notice" ref="noticeBox">
    <!-- :style="{transform: `translateX(-${translateX}px)`}" -->
    <div
      :class="['ng-notice-content', animation ? 'notice-animation' : '']"
      :style="style"
      ref="noticeContent"
    >{{title}}</div>
  </div>
</template>

<script>
  const AniDuration = 5; // s

  export default {
    data() {
      return {
        animation: false,
        // translateX: '0'
        style: {}
      }
    },
    props: {
      title: String
    },

    watch: {
      title: {
        handler() {
          this.$nextTick(() => {
            this.clearTimer();
            this.checkAnim();
          })
        },
        immediate: true
      }
    },

    created() {
      this.curAngle = 0;
    },

    mounted() {
      // this.checkAnim();
    },

    beforeDestroy() {
      this.clearTimer();
    },

    methods: {
      checkAnim() {
        let noticeBox = this.$refs.noticeBox;
        let noticeContent = this.$refs.noticeContent;

        let boxWidth = noticeBox.clientWidth;
        let conWidth = noticeContent.clientWidth;

        this.animation = conWidth > boxWidth;

        if (this.animation) {
          let space = conWidth - boxWidth;

          this.startAnimation(space);
        }
      },

      startAnimation(space) {
        let style = {};

        this.initPosition();

        this.$nextTick(() => {
          // 最外层定时器为了在初始位置停留
          this.time1 = setTimeout(() => {
            style.transitionDuration = `${AniDuration}s`;
            style.transform = `translateX(-${space}px)`;
            this.style = Object.assign({}, style);

            // 内层定时器 +1 是为了让动画结束后停留 1s
            this.time2 = setTimeout(() => {
              this.startAnimation(space);
            }, (AniDuration + 1) * 1000);
          }, 1000);
        })
      },

      initPosition() {
        let style = {};

        style.transform = `translateX(0px)`;
        style.transitionDuration = `0s`;

        this.style = style;
      },

      clearTimer() {
        if (this.time1) {
          clearTimeout(this.time1)
          this.time1 = null;
          this.initPosition();
        }

        if (this.time2) {
          clearTimeout(this.time2)
          this.time2 = null;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-notice {
    position: relative;
    height: 20px;
    overflow: hidden;

    .ng-notice-content {
      position: absolute;
      display: inline-block;
      white-space: nowrap;
      transform: translateX(0);

      &.notice-animation {
        transition-property: all;
        transition-timing-function: ease-in-out;
      }
    }
  }
</style>
