import {
  Axios
} from '@/utils/from-common';

// 购物车列表
export const CartList = (param) => Axios('POST', '/web/cart/list', param);

// 添加购物车
export const AddToCart = (param) => Axios('POST', '/web/cart/add', param);

// 编辑购物车
export const EditCart = (param) => Axios('POST', '/web/cart/save', param);

// 删除购物车
export const DelCart = (param) => Axios('POST', '/web/cart/del', param);

// 商品数量
export const GoodsNumOfCartApi = (param) => Axios('POST', '/web/cart/count', param);