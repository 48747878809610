import {
  Axios
} from '@/utils/from-common';

/* *********************  套餐订单  ********************** */
// 套餐信息
export const Package_orderInfo = (param) => Axios('POST', '/web/goodsorder/info', param);

// 套餐订单 下单
export const Package_orderAdd = (param) => Axios('POST', '/web/goodsorder/add', param);
// 我的套餐 下的套餐列表
export const Package_orderlist = (param) => Axios('POST', '/web/goodsorder/list', param);

export const Package_orderCancel = (param) => Axios('POST', '/web/goodsorder/cancel', param);  //取消订单