<template>
  <span>{{curDuration | formatTimeBySecond}}</span>
</template>

<script>
  export default {
    data() {
      return {
        curDuration: '',
        curMinisecond: '',
      }
    },

    props: {
      // 开始时间 如果为空，那么从当前时间开始
      start: [Date, Object],
      // 倒计时时间 单位s
      countTime: {
        type: [String, Number],
        default: 60
      },
      // 每步步长 单位1s
      step: {
        type: [String, Number],
        default: 1
      },
      // 循环间隔时间 单位ms
      loopSpace: {
        type: [String, Number],
        default: 1000
      }
    },

    beforeDestroy() {
      this.endLoop();
    },

    watch: {
      start: {
        handler(newVal) {
          if (newVal && newVal.getTime()) {
            this.$nextTick(() => {
              this.endLoop();
              this.calcCurTime();
            })
          }
        },
        immediate: true,
      },
      curMinisecond: {
        handler(newVal) {
          if (newVal) {
            let maxMs = this.countTime * 1000;

            newVal = newVal > maxMs ? maxMs : newVal <= 0 ? 0 : newVal;

            this.curDuration = newVal / 1000;
          }
        },
        immediate: true
      }
    },

    methods: {
      calcCurTime() {
        let currentTime = (new Date()).getTime();
        let sDur = this.start ? this.start.getTime() : currentTime;
        let cDur = currentTime;
        let spaceDur = this.countTime * 1000;

        if (cDur > sDur + spaceDur) {
          // this.$emit('end');
        } else {
          this.curMinisecond = sDur + spaceDur - cDur;

          this.startLoop();
        }
      },

      startLoop() {
        this.timer = setInterval(() => {
          this.curMinisecond -= this.loopSpace;

          if (this.curMinisecond <= 0) {
            this.curMinisecond = 0;
            this.endLoop();
            this.$emit('end');
          }
        }, this.loopSpace);
      },

      endLoop() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
