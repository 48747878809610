const Auth = () => import('@/pages/auth/Auth.vue');
const vipAuth = () => import('@/pages/auth/vipAuth.vue');

export default [{
    path: '/authCenter',
    name: 'authCenter',
    meta: {
      isLogin: false
    },
    component: Auth
  },
  {
    path: '/vipauthCenter',
    name: 'vipauthCenter',
    meta: {
      isLogin: false
    },
    component: vipAuth
  },

]