export default {
  email: '邮箱',
  emailNum: '邮箱号码',
  account: '账户名称',
  phone: '手机号',
  phoneNum: '手机号码',
  code: '验证码',
  pwd: '密码',
  username: '用户名',
  country: '国家',
  allSelect: '全选',
  operation: '操作',
  phoneEmail: '手机号/邮箱',

  sthNotEmpty: '{name}不可为空',
  typeSth: '请输入{name}',
  typeAgainSth: '请再次输入{name}',
  selectSth: '请选择{name}',
  confirmCancel: '确认要取消此{name}吗？',
  confirmDel: '确认要删除此{name}吗？',
  canceledTip: '已取消该{name}',
  deledTip: '已删除该{name}',
  addSth: '添加{name}',
  changeSth: '修改{name}',
  sthIsSuccess: '{name}成功'
}