<template>
  <a-select v-bind="$props" @change="triggerChange" :loading="loading">
    <template v-for="item in list">
      <a-select-option
        v-if="item.value !== $val.SettleWayValues.CToC"
        :value="item.value"
        :key="item.value"
      >{{item.label}}</a-select-option>
      <!-- 非公司用户没有公对公付款 -->
      <a-select-option
        v-else-if="userType === $var.SupplierType.COMPANY"
        :value="item.value"
        :key="item.value"
      >{{item.label}}</a-select-option>
       <!--发行音乐人-->
       <a-select-option
        v-else-if="userType === $var.SupplierType.TEAM"
        :value="item.value"
        :key="item.value"
      >{{item.label}}</a-select-option>

    </template>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';
  import formMixin from './_formMixins';
  import {SettleWays} from '@/config/options';

  let props = Object.assign({}, Select.props);

  delete props.open;
  delete props.loading;

  export default {
    data() {
      return {
        list: SettleWays,
        loading: false
      }
    },

    mixins: [formMixin],

    props: {
      /**
       * 用户类型
       * 公对公付款只有公司用户有，个人和团体没有
       */
      userType: String,
      ...props
    },

    created() {
      // this.getData();
    },

    components: {

    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
