export default {
  title: '试听与下载',

  menuAllTry: '全部试听',
  menuRecord: '下载记录',

  downloadEmtpy: '暂无下载记录',
  tryEmtpy: '暂无试听记录',

  musicFormat: '音乐格式',
}