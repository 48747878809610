export * from '~/config/options.value.c'

// 登录方式
export const LoginWay = {
  email: '1',
  phone: '2'
}

// 音频类型
export const AudioType = {
  common: '1', // 一般音乐（试听）
  highQuality: '2', // 高清音乐
}

// 试听类型
export const TryType = {
  try: '1', // 试听类型
}

// 订单支付结果
export const PayResultStatus = {
  success: 'success', // 成功
  doing: 'doing', // 支付中
  error: 'error', // 失败
  expired: 'expired', // 过期
}

// 授权状态
export const AuthStatus = {
  undo: '0', // 未授权,
  did: '1', // 已授权
}

// 用户后台菜单
export const UserMenu = {
  receiptHeader: '1', // 发票抬头
  company: '2', // 公司信息
  address: '3', // 联系地址
  collect: '4', // 我的收藏
  buy: '5', // 已购买
  package: '6', // 我的套餐
  discount: '7', // 优惠券
  order: '8', // 我的订单
  operation: '9', // 操作记录
  basic: '10', // 基本信息
  secure: '11', // 安全绑定
  password: '12', // 修改密码

}