export default {
  title: '我的订单',

  menuUndoPay: '待支付订单',
  menuDone: '已支付订单',

  tableEmtpy: '暂无购买记录',

  orderStatusText: '订单状态',

  activeDiaTitle: '激活音乐授权',
  activeDiaMain: '为保证您的授权真实有效，请如实填写项目信息！',

  labelAuthType: '授权类型',
  labelAuthMain: '授权主体',
  labelAuthProject: '授权项目',
  labelContact: '联系人',
  labelContactMethod: '联系方式',

  pdAuthProject: '请输入项目全称',
  pdContactMethodOfAuth: '请输入授权对象的联系方式',
  pdContactName: '请输入联系人姓名',
  pdAuthMain: '请输入被授权「公司全称」或「个人身份证真实姓名」',

  activeSuccess: '激活成功'
}