<template>
  <div class="form-add">
    <template v-for="(item, index) in list">
      <div v-if="!item.del" class="form-item-add m-bottom-0 flex cen-center" :key="index">
        <div class="flex-1 w-100">
          <slot :item="item.id" :index="index"></slot>
        </div>

        <a-button
          v-if="addIsIcon && !index && canAdd"
          class="font-grey iconfont-trash"
          :class="[containIcon ? 'contain' : 'uncontain']"
          type="link"
          @click="add"
          :style="{top: iconTop}"
          :disabled="disabled"
        >
          <svg class="iconfont">
            <use xlink:href="#iconaddTodo-nav" />
          </svg>
        </a-button>

        <a-button
          v-else-if="canDel"
          class="font-grey iconfont-trash"
          :class="[containIcon ? 'contain' : 'uncontain']"
          type="link"
          @click="remove(index)"
          :style="{top: iconTop}"
          :disabled="disabled"
        >
          <svg class="iconfont">
            <use xlink:href="#icontrash" />
          </svg>
        </a-button>
      </div>
    </template>

    <a-button
      v-if="!addIsIcon && canAdd"
      class="w-100"
      type="dashed"
      @click="add"
      :disabled="disabled"
    >
      <a-icon type="plus" />
      {{addText}}
    </a-button>
  </div>
</template>

<script>
  let id = 0;

  export default {
    data() {
      return {
        list: []
      }
    },

    props: {
      addText: {
        type: String,
        default: '新增'
      },
      isRemove: {
        type: Boolean,
        default: true
      },
      addIsIcon: {
        type: Boolean,
        default: false
      },
      canAdd: {
        type: Boolean,
        default: true,
      },
      iconTop: String, // 右侧图片top值，调整icon位置
      containIcon: {
        type: Boolean,
        default: false
      },
      defaultValue: {
        type: Array,
        default() {
          return []
        }
      },
      disabled: Boolean,
      canDel: {
        type: Boolean,
        default: true
      }
    },

    watch: {
      defaultValue: {
        handler(newVal) {
          if (newVal && newVal.length > 0) {
            this.clear();

            newVal.forEach(() => {
              this.add();
            })

            this.$nextTick(() => {
              this.$emit('update', newVal);
            })
          }
        },
      }
    },

    created() {
      if (this.addIsIcon && this.list.length === 0) {
        this.add();
      }
    },
    methods: {
      remove(index) {
        let item = this.list[index];
        this.list.splice(index, 1, {id: item.id, del: true});
        this.$emit('change', {
          list: this.list,
          handle: this.$var.Handle.del,
          index
        });
      },

      add() {
        this.list = this.list.concat({id: id++, del: false});
        this.$emit('change', {
          list: this.list,
          handle: this.$var.Handle.add,
          index: this.list.length
        });
      },

      clear() {
        this.list = [];
        id = 0;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form-add {
    .form-item-add {
      position: relative;
    }

    /deep/ .iconfont-trash {
      font-size: 11px;

      &.uncontain {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(85%, -50%);
      }

      &.contain {
        padding-right: 0;
      }
    }
  }
</style>