<template>
  <img v-if="status === Status.success" :src="curSrc" :alt="alt" :title="title" />
  <div class="default-image" v-else :style="{backgroundImage: `url(${curSrc})`}"></div>
</template>

<script>
  import {LoadErrorDefaultImage, EmptyDefaultImage} from '~/config/config.c';

  const Status = {
    success: 'success',
    error: 'error',
    empty: 'empty'
  }

  export default {
    name: 'NgImage',

    data() {
      return {
        status: Status.empty,
        Status
      }
    },

    props: {
      src: String,
      alt: String,
      title: {
        default: '',
        type: String
      },
      defaultValue: String,
    },

    watch: {
      src: {
        handler(newVal) {
          this.status = Status.empty;

          this.$nextTick(() => {
            if (newVal) {
              this.setSrc(newVal);
            }
          })
        },
        immediate: true
      },
    },

    computed: {
      curSrc() {
        let status = this.status;
        let isSuccess = status === Status.success;
        let isError = status === Status.error;

        if (isSuccess) {
          return this.src;
        } else {
          return this.defaultValue ? this.defaultValue : isError ? LoadErrorDefaultImage : EmptyDefaultImage;
        }
      }
    },

    created() {
      this.initEvent();
    },

    beforeDestroy() {
      this.img = null;
      this.isError = false
    },

    methods: {
      setSrc(src) {
        if (!this.img) {
          this.img = new Image();
        }

        this.img.src = src;
      },

      initEvent() {
        let that = this;
        let image = that.img;

        if (!image) {
          image = new Image();
          that.img = image;
        }

        image.onload = function () {
          that.status = Status.success;
        }

        image.onerror = function () {
          if (that.status === Status.error) {
            that.status = Status.empty;
          } else {
            that.status = Status.error;
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .default-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
  // img {
  //   max-width: 100%;
  //   max-height: 100%;
  // }
</style>
