// 上传证件照最大内存
export const UploadLicenseMaxSize = 5; // 单位MB

// 下载最大超时时间 5min
export const DownloadTimeout = 5 * 60 * 1000;

// 发送验证码成功后，倒计时 60s
export const SendCodeCountDown = 60;

// 订单有效期 60min
export const OrderExpirs = 60 * 60;

// 电话区号下拉框 默认选中的value值
export const PhoneCodeDefault = '1000'; // 中国

// 证件类型 默认选中的value值
export const CertificateDefault = '1'; // 身份证

// 没有数据时默认表示方法
export const emptyText = '--';

// 线上环境请求接口域名
// export const ServeBaseHost = process.env.VUE_APP_REQUEST === 'serve' ? '/' : process.env.VUE_APP_SERVER_HOST;
export const ServeBaseHost = process.env.VUE_APP_REQUEST === 'serve' ? 'https://test-api.spark.art/' : process.env.VUE_APP_SERVER_HOST;

// 图片加载失败 默认图片
export const LoadErrorDefaultImage = require('~/assets/images/default-image.png');

// 路径为空 默认图片
export const EmptyDefaultImage = LoadErrorDefaultImage;

// 官网域名
export const WebsiteHost = process.env.VUE_APP_REQUEST === 'serve' ? 'http://test.spark.art' : 'https://www.spark.art';