<template>
  <a-select v-bind="$props" @change="triggerChange" :loading="loading">
    <template v-for="item in list">
      <a-select-option :value="item.value" :key="item.value">{{item.label}}</a-select-option>
    </template>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';
  import formMixin from './_formMixins';
  import {LinksOptions} from '@/config/options';

  let props = Object.assign({}, Select.props);

  delete props.open;
  delete props.loading;

  export default {
    data() {
      return {
        list: LinksOptions,
        loading: false
      }
    },

    mixins: [formMixin],

    props: {
      ...props
    },

    created() {
      // this.getData();
    },

    methods: {
      handleData(data, type) {
        return data.map(item => {
          switch (type) {
            case 'code':
              item.value = `+${item.prefix}`;
              break;
            case 'text':
              item.value = item.cn;
              break;
            case 'both':
              item.value = `${item.cn}+${item.prefix}`;
              break;
          }

          return item;
        })
      },

      getData() {
        let phoneCodeArea = this.$root.$PhoneCodeArea;

        if (phoneCodeArea && phoneCodeArea.length > 0) {
          this.list = phoneCodeArea;
        } else {
          this.loading = true;

          this.$axios.PhoneCodeArea({}).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.list = this.handleData(data.data ? data.data.list : [], this.showType);
              this.$root.$PhoneCodeArea = this.list;
            }

            this.loading = false;
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
