export const codeLang = {
    en: {
        '400': 'Wrong request',
        '401': 'Unauthorized, please login again.',
        '403': 'Access denied',
        '404': 'Request error, the resource was not found.',
        '405': 'Request method not allowed',
        '408': 'Request timeout',
        '500': 'Server side error',
        '501': 'Network not implemented',
        '502': 'Network error',
        '503': 'Service Unavailable',
        '504': 'Network Timeout',
        '505': 'The HTTP version does not support the request',
        'default': 'Connection error ',
        'error': 'Failed to connect to server'
    },
    zh_CN: {
        '400': '错误请求',
        '401': '未授权，请重新登录',
        '403': '拒绝访问',
        '404': '请求错误,未找到该资源',
        '405': '请求方法未允许',
        '408': '请求超时',
        '500': '服务器端出错',
        '501': '网络未实现',
        '502': '网络错误',
        '503': '服务不可用',
        '504': '网络超时',
        '505': 'http版本不支持该请求',
        'default': '连接错误',
        'error': '连接到服务器失败'
    }
}
