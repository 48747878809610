<template>
  <a-upload
    :accept="accept"
    :action="actionUrl"
    :method="method"
    :multiple="multiple"
    :headers="requestHeaders"
    :file-list="fileList"
    :beforeUpload="onBeforeUpload"
    :disabled="disabled"
    @change="(info) => {handleChange([...info.fileList])}"
    :customRequest="onCustomRequest"
  >
    <a-button :type="submitBtnType" :disabled="disabled">
      <a-icon type="upload" />
      {{text}}
    </a-button>
    <slot></slot>
  </a-upload>
</template>

<script>
  import {Upload as AUpload, Icon as AIcon} from 'ant-design-vue';
  import axios from 'axios'
  import CosAuth from '~/utils/cosAuth.c'
  import {getFileSuffixByPath} from '@/utils/function'

  export default {
    name: 'FormUploadFiles',
    data() {
      return {
        fileList: [],
        actionUrl: '',
        // requestParam: {},
        requestHeaders: {},
        method: 'put'
      }
    },

    props: {
      accept: {
        type: String,
        default: 'audio/wav'
      },
      text: String,
      maxSize: Number,
      showTip: {
        type: Boolean,
        default: true
      },
      disabled: Boolean,
      multiple: {
        type: Boolean,
        default: true
      },
      defaultValue: {
        type: Array,
        default() {
          return [];
        }
      },
      submitBtnType: String
      // value: {
      //   type: Array,
      //   default() {
      //     return [];
      //   }
      // }
    },

    watch: {
      defaultValue(newVal) {
        if (newVal && newVal.length > 0) {
          this.fileList = newVal;
          this.$emit('change', this.fileList);
        }
      },

      // value: {
      //   handler(newVal) {
      //     this.handleChange(newVal || []);
      //   },
      //   immediate: true
      // }
    },

    components: {
      AUpload,
      AIcon
    },

    methods: {
      checkFileSize(file, message = true) {
        if (this.maxSize && (file.size / 1024 / 1024 > this.maxSize)) {
          message && this.$message.error(`上传文件不能大于${this.maxSize}MB`);
          return false;
        }

        return true;
      },

      checkFileType(file, message = true) {
        let fileName = file.name;
        let suffix = getFileSuffixByPath(fileName);

        if (this.accept && !~this.accept.indexOf(suffix)) {
          message && this.$message.error(`请上传正确的文件类型`);
          return false;
        }

        return true;
      },

      onCustomRequest(request) {
        let url = request.action;
        let file = request.file;

        request.onUploadProgress = (event) => {
          event.percent = event.loaded / event.total * 100;

          request.onProgress(event);
        };

        delete request.method;
        delete request.filename;
        delete request.data;

        axios.put(url, file, request).then(request.onSuccess).catch(request.onError);
      },

      onBeforeUpload(file) {
        let that = this;

        if (!this.checkFileSize(file)) return false;

        if (!this.checkFileType(file)) return false;

        return new Promise((resolve, reject) => {
          (function (file) {
            that.getUploadConfig().then(({data}) => {
              let filename = file.name;
              let fileSuffix = that.get_suffix(filename);
              let random = that.getTimeRandom() + that.random_string();
              let randomName = `${random}${fileSuffix}`;
              let key = data.dir + randomName;
              let info = that.setRequestParam(data, '/' + key);
              let XCosSecurityToken = info.XCosSecurityToken;
              let auth = info.Authorization;
              let requestHeaders = that.requestHeaders;

              requestHeaders.Authorization = auth;

              file.path = key;

              XCosSecurityToken && (requestHeaders['x-cos-security-token'] = XCosSecurityToken);

              that.requestHeaders = requestHeaders;
              that.actionUrl = data.host + '/' + key;

              resolve(file);
            }, reject);
          }(file))
        })
      },

      // 对更多字符编码的 url encode 格式
      camSafeUrlEncode: function (str) {
        return encodeURIComponent(str)
          .replace(/!/g, '%21')
          .replace(/'/g, '%27')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
          .replace(/\*/g, '%2A');
      },

      setRequestParam(data, key) {
        let credentials = data.policy ? data.policy.credentials : {};

        return {
          XCosSecurityToken: credentials.sessionToken,
          Authorization: CosAuth({
            SecretId: credentials.tmpSecretId,
            SecretKey: credentials.tmpSecretKey,
            Method: this.method,
            Pathname: key,
          }),
        };
      },

      random_string(len) {
        len = len || 10;
        var chars = '0123456789'; // ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678
        var maxPos = chars.length;
        var pwd = '';

        for (let i = 0; i < len; i++) {
          pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }

        return pwd;
      },

      getTimeRandom() {
        let now = new Date();
        let hour = now.getHours().toString().padStart(2, '0');
        let min = now.getMinutes().toString().padStart(2, '0');
        let second = now.getSeconds().toString().padStart(2, '0');

        return `${hour}${min}${second}`;
      },

      get_suffix(filename) {
        let pos = filename.lastIndexOf('.');
        let suffix = '';

        if (pos != -1) {
          suffix = filename.substring(pos)
        }

        return suffix;
      },

      handleChange(fileList) {
        // let fileList = [...info.fileList];

        if (!this.multiple && fileList && fileList.length > 1) {
          fileList = [fileList[fileList.length - 1]];
        }

        this.fileList = fileList.filter(file => this.checkFileSize(file, false) && this.checkFileType(file, false));

        let isUploading = fileList.some(item => item.status === 'uploading');

        if (!isUploading) {
          let backFileList = fileList.map(item => {
            let {path, originFileObj: {name}, status} = item;

            return {path, name, status};
          })

          this.$emit('change', backFileList);
        }

        this.$emit('uploading', isUploading);
      },

      getUploadConfig() {
        let params = {upload_type: 3};

        return new Promise((resolve, reject) => {
          this.$axios.UploadConfig(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
            }

            reject(res);
          }, reject)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  // /deep/ .ant-upload-disabled {
  //   .ant-btn {
  //     cursor: not-allowed;
  //   }
  // }
  /deep/ {
    .ant-upload.ant-upload-select {
      display: block;
    }
  }
</style>
