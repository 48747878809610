export default {
  methods: {
    // 切换音乐列表key值，变成音乐播放器可以使用的格式
    toggleListKey(obj = {}, url_name, music_name = 'name', sup_name = 'suplr_name') {
      url_name = url_name ? url_name : (obj.is_buy == '1' ? 'origin_url' : 'audition_url')

      let {
        [url_name]: url, [music_name]: name, [sup_name]: com_name
      } = obj;

      return Object.assign({}, obj, {
        url,
        name,
        com_name
      })
    },
  }
}