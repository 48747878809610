<template>
  <div
    :class="['ng-drawer', !visible ? 'down' : '', theme ? theme: '']"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseout"
  >
    <slot></slot>
    <div v-if="showClose" class="control-handle" @click="$emit('update:visible', !visible)">
      <a-icon v-show="visible" type="down" />
      <a-icon v-show="!visible" type="up" />
    </div>
  </div>
</template>

<script>
  import {Icon} from 'ant-design-vue';

  // const TimeSpace = 5 * 1000;

  export default {
    data() {
      return {
        isFixed: false,
      }
    },

    watch: {
      isFixed(newVal) {
        newVal && clearTimeout(this.timer);
      },
    },

    components: {
      AIcon: Icon
    },

    props: {
      visible: Boolean,
      theme: String,
      duration: {
        type: Number,
        default: 5 * 1000
      },
      showClose: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      onFixed() {
        this.isFixed = !this.isFixed;
      },

      onMouseEnter() {
        this.inContent = true;

        clearTimeout(this.timer);
      },

      onMouseout() {
        this.inContent = false;

        !this.isFixed && this.transitionHide();
      },

      transitionHide() {
        clearTimeout(this.timer);

        if (this.visible && this.duration) {
          this.timer = setTimeout(() => {
            this.$emit('update:visible', false)
          }, this.duration)
        }
      },

    }
  }
</script>

<style lang="scss" scoped>
  .ng-drawer {
    // transform: translateY(-100%);
    // transition: transform linear 0.2s;

    &.down {
      transform: translateY(0);
    }

    .control-handle {
      position: absolute;
      right: 20px;
      top: 0;
      width: 42px;
      height: 25px;
      border: 1px solid #efefef;
      transform: translateY(-25px);
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      background: #e3e3e3;
      cursor: pointer;
    }

    &.dark {
      .control-handle {
        border-color: #000;
        background-color: #000;
        color: #fff;
      }
    }
  }
</style>
