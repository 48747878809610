<template>
  <a-cascader v-bind="$props" :options="listOptions" v-on="$listeners"></a-cascader>
</template>

<script>
  import {Cascader} from 'ant-design-vue';

  let props = Object.assign({}, Cascader.props);

  delete props.popupVisible;
  delete props.loading;

  export default {
    name: 'FormLibrary',
    data() {
      return {
        list: [],
        loading: true
      }
    },

    props: {
      ...props,
      all: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ACascader: Cascader
    },

    computed: {
      listOptions() {
        return this.list.map(({user_id, name, lib}) => ({
          label: name,
          value: user_id,
          children: lib && lib.length > 0 ? lib.map(({lib_id, name}) => ({label: name, value: lib_id})) : []
        }))
      }
    },

    created() {
      this.getData();
    },

    methods: {
      getData() {
        this.$axios.GetLibraryList({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let options = data.data ? data.data.list : [];

            if (this.all) {
              options.unshift({
                name: '全部',
                user_id: ''
              })
            }

            this.list = options;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
