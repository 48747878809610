<template>
  <a-select v-bind="$props" :options="listOptions" @change="triggerChange"></a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';

  let props = Object.assign({}, Select.props);

  delete props.open;

  export default {
    data() {
      return {
        list: [],
        keyIndex: 0,
      }
    },

    props: {
      ...props,
      scene_type: String,
    },

    computed: {
      listOptions() {
        let finalArr = this.list.filter(item => item.type === this.scene_type)

        let ways = finalArr && finalArr.length > 0 ? (finalArr[0].use_mode || []) : [];

        return [...ways].map(({name}) => ({label: name, value: name}))
      }
    },

    created() {
      this.getData();
    },

    methods: {
      triggerChange(changedValue) {
        this.$emit('change', changedValue);
      },

      getData() {
        this.$axios.GetSceneWay({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let options = data.data ? data.data.list : [];

            this.list = options;

            this.$emit('changeData', this.list);
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
