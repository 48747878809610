<template>
  <a-dropdown class="table-switch" :trigger="['click']" :disabled="disabled">
    <a-button class="switch-button" :loading="isLoading">
      <template v-if="selectItem.class">
        <span v-show="!isLoading" class="status anticon" :class="selectItem.class"></span>
      </template>
      <!-- 是为了兼容之前的 启用，禁用等的写法 -->
      <template v-else>
        <span
          v-show="!isLoading"
          class="status anticon"
          :class="value === closeID ? 'close' : 'open'"
        ></span>
      </template>
      <span>{{selectItem[format.name]}}</span>
      <a-icon type="down" />
    </a-button>

    <a-menu slot="overlay" @click="onClickMenu">
      <template v-for="item in options">
        <a-menu-item
          v-if="item[format.id] != value"
          :value="item[format.id]"
          :key="item[format.id]"
        >
          <a href="javascript:;">{{item[format.name]}}</a>
        </a-menu-item>
      </template>
    </a-menu>
  </a-dropdown>
</template>

<script>
  import {OpenAndCloseStatus} from '@/config/options'

  export default {
    name: 'TableSwitch',

    data() {
      return {
        value: 0,
        closeID: 0,
        selectItem: {},
        menuVisible: false,
        isLoading: false
      }
    },

    props: {
      options: {
        type: Array,
        default() {
          return OpenAndCloseStatus
        }
      },
      defaultValue: [Number],
      disabled: Boolean,
      loading: Boolean,
      change: {
        type: Function,
        default() {}
      },
      // 数据中key的切换
      format: {
        type: Object,
        default() {
          return {id: 'id', name: 'name'}
        }
      },
    },

    watch: {
      defaultValue: {
        handler(newVal) {
          this.value = newVal;
        },
        immediate: true
      },
      value: {
        handler(newVal) {
          this.selectItem = this.options.filter(item => item[this.format.id] == newVal)[0] || {};
        },
        immediate: true
      },
      loading(newVal) {
        this.isLoading = newVal;
      }
    },

    methods: {
      onClickMenu({key}) {
        const next = () => {
          this.value = key;
        };

        const loading = (bool) => {
          this.isLoading = bool;
        }

        typeof this.change === 'function' && this.change(key, this.options, next, loading)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .table-switch {
    padding-left: 12px;
    padding-right: 12px;
    font-size: $font-size-sm;
    line-height: 1.899;
    padding-left: 24px !important;

    /deep/ .ant-select-selection {
      border-radius: 50px;
    }

    &.switch-button {
      border-radius: 32px;
      background-color: #f8f8f8;
      border: 0;
    }

    .status {
      width: 10px;
      height: 10px;
      border-width: 2px;
      border-style: solid;
      border-radius: 5px;
      // margin-left: -14px;

      &.warn {
        border-color: #ffaf00;
      }

      &.close {
        border-color: #ff1947;
      }

      &.open {
        border-color: #41cf60;
      }
    }

    &[disabled] .status {
      border-color: $disable-color;
    }

    /deep/ .anticon-loading,
    .status {
      margin-left: 0 !important;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
