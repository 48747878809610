<template>
  <a-input class="line-height-unset" v-bind="$props" @change="onChange" @blur="onBlur">
    <span
      class="count-box"
      slot="suffix"
      v-if="maxLength && showCounter"
    >{{curNumber}}/{{maxLength}}</span>
  </a-input>
</template>

<script>
  import {Input} from 'ant-design-vue';

  export default {
    name: 'NgInput',

    data() {
      return {

      }
    },
    props: {
      showCounter: Boolean,
      ...Input.props
    },

    computed: {
      curNumber() {
        return this.value ? this.value.length : 0;
      }
    },

    components: {

    },
    methods: {
      onChange(newVal) {
        this.$emit('change', newVal)
      },

      onBlur(newVal) {
        this.$emit('blur', newVal)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .count-box {
    color: rgba(0, 0, 0, 0.25);
  }
</style>
