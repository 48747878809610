<template>
  <div class="amp-box w-100 h-100" ref="canvasBox">
    <canvas class="canvas" ref="canvas" @click="onClickCanvas"></canvas>

    <slot></slot>
  </div>
</template>

<script>
  const BarWidth = 0.8; // 线条的宽度
  const BarMinHeight = 1; // 线条最小高度
  const BarMinSpace = 1; // 线条与线条之间最小间距
  const BarColorBack = '#eae9ea'; // 线条颜色
  const BarColor = 'rgba(0, 0, 0, 0.85)'; // 线条颜色


  export default {
    data() {
      return {
      }
    },

    props: {
      // list: Array,
      percent: Number,
      list:Array,
    },

    watch: {
      list: {
        handler(newVal) {
          this.$nextTick(() => {
            if (newVal && newVal.length > 0) {
              this.clearPath();
              this.finalList = this.getDrawList(newVal);
              this.drawLine(this.finalList, BarColorBack);
            } else {
              this.clearPath();
            }
          })
        },
        immediate: true
      },
      percent: {
        handler(newVal) {
          let list = this.finalList || [];
          let len = list.length;
          let finalList = list.slice(0, Math.floor(len * newVal));

          this.clearPath();

          this.drawLine(list, BarColorBack);
          this.drawLine(finalList, BarColor, this.barSpace);
        }
      }
    },

    mounted() {
      this.initCanvas();

      this.initWH();
    },

    methods: {
      onClickCanvas(event) {
        let {offsetX} = event;
        let width = this.canvas.width;
        let percent = offsetX / width;

        this.$emit('move', percent)
      },
      initCanvas() {
        let canvas = this.$refs.canvas;
        let context = canvas.getContext("2d");
        let canvasBox = this.$refs.canvasBox;

        this.canvas = canvas;
        this.context = context;
        this.canvasBox = canvasBox;
      },

      initWH() {
        let canvasBox = this.canvasBox;

        let cbw = canvasBox.clientWidth;
        let cbh = canvasBox.clientHeight;

        this.canvas.width = cbw;
        this.canvas.height = cbh;
      },

      getDrawList(data) {
        let {width: cw} = this.canvas;

        return this.filterExtraList(data, cw, BarWidth, BarMinSpace);
      },

      drawLine(data, color, space) {
        let ctx = this.context;
        let {width: cw, height: ch} = this.canvas;
        let hah = ch / 2;

        ctx.strokeStyle = color;
        ctx.lineWidth = BarWidth;

        let listLen = data.length;

        // 线条之间的间距
        if (!space) {
          let initSpace = (cw - listLen * BarWidth) / (listLen - 1);

          space = initSpace;

          this.barSpace = initSpace; // 铺满整个canvas，每个线条之间的间距
        }

        data.forEach((item = 0, index) => {
          let ratio = Math.abs(item) <= 1 ? Math.abs(item) : 1;
          let minRatio = BarMinHeight / hah;

          ratio = ratio < minRatio ? minRatio : ratio;

          let y1 = (1 - ratio) * hah;
          let y2 = (1 + ratio) * hah;
          let x = (index) * (BarWidth + space);

          this.drawSingleLine(ctx, [x, y1], [x, y2]);
        })
      },

      // 获取线条数量
      getBarLen(listLen, width, barWidth, minSpace) {
        let maxNum = Math.floor(width / (barWidth + minSpace));

        return listLen <= maxNum ? listLen : maxNum;
      },

      // 过滤多余数据
      filterExtraList(list = [], width, barWidth, minSpace) {
        let len = list.length;
        let barNum = this.getBarLen(len, width, barWidth, minSpace);

        if (barNum <= 0) return []

        return len <= barNum ? list : this.averageArr(list, Math.ceil(len / barNum)).map(item => item[0])
      },

      // num 每个子数组的长度
      averageArr(arr = [], num) {
        num = Number(num);

        let remNum = arr.length % num;
        let finalItem = arr[arr.length - 1];

        remNum !== 0 && (arr = arr.concat(new Array(num - remNum).fill(finalItem)))

        return arr.reduce((pre, cur, index) => {
          if (!((index + 1) % num)) {
            let len = pre.length;
            pre.push(arr.slice(len * num, (len + 1) * num))
          }

          return pre;
        }, [])
      },

      drawSingleLine(ctx, start, end) {
        ctx.beginPath();
        ctx.moveTo(...start)
        ctx.lineTo(...end);
        ctx.closePath();
        ctx.stroke();
      },

      clearPath() {
        let {width, height} = this.canvas;

        this.context && this.context.clearRect(0, 0, width, height);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .canvas {
    cursor: pointer;
  }
</style>
