import Vue from 'vue';

import {
  Icon,
  Button,
  Message,
  Modal,
  Row,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Radio,
  Select,
  Space,
  Divider,
  Drawer,
  Slider,
  Popover,
  Tabs,
  // Empty
} from 'ant-design-vue';

// console.log(Empty)

Vue.use(Tabs);
Vue.use(Button);
Vue.use(Row);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Form);
Vue.use(Input);
Vue.use(Layout);
Vue.use(Divider);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Space);
Vue.use(Drawer);
Vue.use(Slider);
Vue.use(Popover);

import {
  NgImage,
} from '~/components/component.c';

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2364427_x6yglv10v8q.js',
});

Vue.component(NgImage.name, NgImage);
Vue.component('IconFont', IconFont);

// message
Message.config({
  top: `calc(50% - 100px)`,
  duration: 1,
  maxCount: 1,
});

const createMessageElement = function (type, icon) {
  return function (content = {}) {
    const options = typeof content === 'string' ? {
      content
    } : content;

    return Message[type](Object.assign(options, {
      icon: icon ? (h) => {
        return h('svg', {
            class: {
              iconfont: true, anticon: true
            }
          },
          [
            h('use', {
              attrs: {
                href: icon
              }
            })
          ]
        )
      } : ' '
    }))
  }
}

Vue.prototype.$message = Object.assign({}, Message, {
  success: createMessageElement('success', '#iconcorrect-empty'),
  warning: createMessageElement('success', '#iconwarning'),
  error: createMessageElement('error', '#iconerror'),
  info: createMessageElement('info', '#iconsmile'), // 笑脸
  text: createMessageElement('info', null), // 纯文本
})

Vue.prototype.$confirm = function (options) {
  return Modal.confirm(Object.assign({
    okButtonProps: {
      props: {
        size: 'large'
      }
    },
    cancelButtonProps: {
      props: {
        size: 'large'
      }
    },
  }, options))
}