export default {
  openStatus: '启用',
  closeStatus: '停用',

  // 审核状态
  verifyStatusWating: '待审核',
  verifyStatusFinished: '审核完成',

  // 审核结果
  verifyResultSuccess: '成功',
  verifyResultRefuse: '拒绝',

  certificateIdentify: '身份证',
  certificatePassport: '护照',
  certificateDrive: '驾照',

  langChinese: '中文',
  langEnglish: '英文'
}