import {
    Axios
  } from '@/utils/from-common';

//订阅会员页
export const VIPLIST = (param) => Axios('POST', '/web/vip/viplist', param); //商品列表
export const qrCode = (param) => Axios('POST', '/web/vip/qrCode', param);  //生成订阅二维码【支付宝和微信】
export const PayCheckOrder = (param) => Axios('POST', '/web/vip/payCheck', param);  //检查二维码状态【支付宝和微信】
export const viporgPay = (param) => Axios('POST', '/web/vip/orgPay', param);  //公对公付款

export const order = (param) => Axios('POST', '/web/vip/order', param);  //新建订单
export const qrCodeV2 = (param) => Axios('POST', '/web/vip/qrCodeV2', param);  //获取二维码

export const vipCancel = (param) => Axios('POST', '/web/vip/cancel', param);  //取消订单
//我的-授权认证页
export const vipAuthlist = (param) => Axios('POST', '/web/vip/vipAuthlist', param); //认证列表
export const saveAuth = (param) => Axios('POST', '/web/vip/saveAuth', param);  //保存认证
export const saveAuthV2 = (param) => Axios('POST', '/web/vip/saveAuthV2', param);  //保存认证 新版本和order_id绑定

//我的-vip购买记录
export const vipgoodsList = (param) => Axios('POST', '/web/vip/userViplist', param);  //用户权益记录
export const viporderList = (param) => Axios('POST', '/web/vip/userOrderlist', param);  //用户权益记录
export const viporderInfo = (param) => Axios('POST', '/web/vip/vipOrderInfo', param);  //用户权益记录
//个人中心-订阅会员授权记录
export const VipAuthList = (param) => Axios('POST', '/web/vip/licenselist', param);
export const VipAuthInfo = (param) => Axios('POST', '/web/vip/licenseinfo', param);
