// import {
//   toOptionName
// } from '@/utils/function';

// 全局函数
import {
  AuthAreaOfMusicOptions,
  BusinessSceneOptions,
  AuthTimeOptions
} from '@/config/options';
import {
  toOptionName
} from '@/utils/function';


export default {
  install(Vue) {
    // 整数转为小数
    Vue.filter('toDecimals', (value) => {
      value = `${value || '0'}`;

      if (value && !~value.indexOf('.')) {
        return (value / 100).toFixed(2);
      }

      return value;
    })

    // 整数转为小数
    Vue.filter('toPrice', (value) => {
      let tp = typeof value;

      if (tp === 'number' || tp === 'string') {
        value = parseFloat(value)
      } else {
        value = 0;
        console.warn('value is not number or string');
      }

      return value.toFixed(2);
    })

    Vue.filter('toArea', (value) => {
      return toOptionName(AuthAreaOfMusicOptions, value);
    })

    Vue.filter('toAuthScene', (value) => {
      return toOptionName(BusinessSceneOptions, value);
    })

    Vue.filter('toAuthPeriod', (value) => {
      return toOptionName(AuthTimeOptions, value);
    })
  }
}