let observer = {
  subscriber: {},

  subscribe: function (key, callback) {
    if (!(callback instanceof Function)) throw new Error('订阅回调必须是函数');

    if (!this.subscriber[key]) this.subscriber[key] = [];

    // 去重
    if (!~this.subscriber[key].indexOf(callback)) {
      this.subscriber[key].push(callback);
    }
  },

  unsubscribe: function (key) {
    this.subscriber[key] = [];
  },

  publish: function (key, ...args) {
    let currSub = this.subscriber[key];

    if (currSub && currSub.length > 0) {
      currSub.forEach(item => item.apply(null, args));
    } else {
      console.error('current subscribe is empty');
    }
  }
};

export default observer;