const termsOfService = () => import('@/pages/termsOfService/termsOfService.vue');
const termsOfServiceVip = () => import('@/pages/termsOfService/termsOfServiceVip.vue');

export default [{
    path: '/termsOfService',
    name: 'termsOfService',
    meta: {
      isLogin: false
    },
    component: termsOfService
  }, 
  {
    path: '/termsOfServiceVip',
    name: 'termsOfServiceVip',
    meta: {
      isLogin: false
    },
    component: termsOfServiceVip
  },
]