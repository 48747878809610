<template>
  <a-select
    v-if="rank === 'first'"
    v-bind="$props"
    :class="[wrapClassName ? wrapClassName : '']"
    :options="list.map(item => ({label: item[fieldNames_inner.label], value: item[fieldNames_inner.value]}))"
    @change="triggerChangeSelect"
    v-model="curValue"
    :allowClear="false"
  ></a-select>
  <a-cascader
    v-else
    v-bind="$props"
    :class="[wrapClassName ? wrapClassName : '']"
    :options="list"
    :fieldNames="fieldNames_inner"
    @change="triggerChange"
    v-model="curValue"
    :allowClear="false"
  />
</template>

<script>
  import {Cascader as ACascader, Select as ASelect} from 'ant-design-vue';

  let props = Object.assign({}, ACascader.props);

  delete props.popupVisible;
  delete props.value;

  export default {
    name: "FormCountry",
    data() {
      return {
        list: [],
        fieldNames_inner: {
          label: 'name_cn', value: 'address_id', children: 'child'
        },
        curValue: [],
      }
    },

    watch: {
      value: {
        handler(newVal) {
          this.$nextTick(() => {
            this.setDefaultValue(this.list, newVal);
          })
        },
      },
    },

    mounted() {
      this.getList(undefined, this.rank).then(() => {
        this.setDefaultValue(this.list, this.value);
      });
    },

    components: {
      ACascader,
      ASelect
    },

    props: {
      all: Boolean,
      value: [String, Number],
      // area: String, // 区域范围 all 所有的  part 部分（后台获取）
      rank: String, // 等级 first 一级，second 二级  third 三级
      method: String, // 请求下拉列表方法
      ...props,
      wrapClassName: String
    },

    methods: {
      setDefaultValue(list, value, rank) {
        if (rank === 'first') {
          this.curValue = value;
        }
        else {
          this.curValue = this.$getAllValueAccrodingFinal(list, value, 'address_id');
        }
      },

      getList(params) {
        return new Promise(resolve => {
          let isCustomeMethod = this.method && typeof this.$axios[this.method] === 'function';
          let storage_list = this.$storage.get(this.$var.STORAGE.COUNTRY_AREA_OPTIONS);

          if (!isCustomeMethod && Array.isArray(storage_list) && storage_list.length > 0) {
            this.all && this.addAllOption(storage_list);
            this.list = storage_list;
            return;
          }

          this.list.loading = true;

          let method = !isCustomeMethod ? 'CountryArea' : this.method;

          this.$axios[method](params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let list = data.data && data.data.list ? data.data.list : [];

              this.all && this.addAllOption(this.list);

              this.list = list;

              !isCustomeMethod && list && list.length > 0 && this.$storage.set(this.$var.STORAGE.COUNTRY_AREA_OPTIONS, list);

              resolve(this.list);
            }
          })
        })
      },

      addAllOption(list) {
        list.unshift({name_cn: '全部', address_id: '0'});
      },

      triggerChange(changedValue) {
        this.$emit('change', changedValue.slice(-1)[0]);
      },

      triggerChangeSelect(value) {
        this.$emit('change', value);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>