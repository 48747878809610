export default {
  startTitle: '帮作品创造更多收入',
  startDescribe: '上传你的作品，让Spark来照亮前进的道路<br>通过宣传和发行获得更多曝光和版税',
  enterInstance: '立即入驻',

  enterBtnMusician: '音乐人入驻',
  enterBtnTeam: '团体入驻',
  enterBtnCompany: '公司入驻',

  doingTitle: 'Spark {0}认证',
  doingDescribe: '成为 Spark 认证的{0}，通过音乐授权与制作获得版权收益……',

  finishedTitle: '成功提交申请',
  finishedDescribe: '您的申请结果会在3-5个工作日内发送至您的邮箱，期待合作~',

  titleBaseInfo: '基础信息',
  titleConnectInfo: '联系信息',
  titleProductionInfo: '作品信息',

  tipSubmitSuccessSupply: '已成功提交申请',

  tipAgreement: '请阅读并同意《SparkMusic 音乐分销协议》后再提交',
}