<template>
  <div class="ng-list">
    <a-spin :spinning="loading">
      <template v-if="list && list.length>0" :style="{height: scroll.y, overFlow: 'hidden'}">
        <template v-for="item in list">
          <slot :item="item"></slot>
        </template>
      </template>
      <template v-else>
        <div class="flex cen-center" style="height: 300px;">
          <a-empty :description="emptyText" />
        </div>
      </template>
    </a-spin>

    <div class="pagination-box w-100 clear">
      <a-pagination
        class="f-r"
        :current="current"
        :total="total"
        :pageSize="page_size"
        :hideOnSinglePage="true"
        @change="onChangePagination"
      />
    </div>
  </div>
</template>

<script>
  import {Spin, Empty, Pagination} from 'ant-design-vue';

  export default {
    data() {
      return {
        list: [],
        current: 1,
        total: 0,
        page_size: 0,
        loading: false,
      }
    },

    props: {
      urlFunc: Function,
      params: {
        type: Object,
        default() {
          return {}
        }
      },
      idKey: String,
      changeData: Array,
      emptyText: String,
      load: Boolean,
      scroll: {
        type: Object,
        default() {
          return {}
        }
      },
      dataList: {
        type: Array,
        default() {
          return []
        }
      }
    },

    computed: {
      isRequest() {
        return typeof this.urlFunc === 'function' && (!this.dataList || !this.dataList.length > 0);
      }
    },

    watch: {
      params: {
        handler(newVal) {
          this.$nextTick(() => {
            if (newVal && typeof newVal === 'object' && this.isRequest) {
              this.newParams = Object.assign({}, this.newParams, newVal);

              newVal && newVal.page && (this.current = parseInt(newVal.page) || 1);

              this.getData(this.newParams);
            }
          })
        },
        immediate: true
      },

      changeData(newVal) {
        if (newVal && newVal.length > 0) {
          let musicIdObj = {};
          let musicIdList = newVal.map(item => {
            let id = item[this.idKey];

            musicIdObj[id] = item;

            return id ? id : 0;
          });

          this.list = this.list.map(item => {
            let id = item[this.idKey] ? item[this.idKey] : 0

            return !~musicIdList.indexOf(id) ? item : Object.assign({}, item, musicIdObj[id] || {});
          })

          this.$emit('listChange', this.list);
        }
      },

      load(newVal) {
        this.$nextTick(() => {
          if (newVal && this.isRequest) {
            this.getData(this.newParams);
          }
        })
      },

      reload(newVal) {
        this.$nextTick(() => {
          if (newVal && this.isRequest) {
            this.newParams.page = 1;
            this.getData(this.newParams);
          }
        })
      },

      dataList: {
        handler(newVal) {
          this.$nextTick(() => {
            if (!this.isRequest) {
              this.list = newVal;
            }
          })
        },
        immediate: true
      }
    },

    components: {
      APagination: Pagination,
      ASpin: Spin,
      AEmpty: Empty
    },

    methods: {
      getData(newParams) {
        if (typeof this.urlFunc !== 'function') return false;

        this.loading = true;

        typeof this.urlFunc === 'function' && this.urlFunc(newParams).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let newData = res.data.data;

            this.list = newData ? newData.list : [];
            this.total = newData.total;
            this.page_size = newData.page_size;

            this.$emit('listChange', this.list);
            this.$emit('dataChange', newData);
          }

          this.$emit('update:load', false);
          this.$emit('update:reload', false);

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      onChangePagination(current) {
        let page = {page: current};

        this.current = current;

        this.newParams = Object.assign({}, this.newParams, page);

        this.$root.$goSearch(page);

        window.scrollTo(0, 0);

        this.getData(this.newParams);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
