<template>
  <a-modal
    title="修改密码"
    width="500px"
    v-model="show"
    :confirm-loading="confirmLoading"
    centered
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :wrapper-col="{ span: 20, offset: 2 }">
      <!-- 姓名 -->
      <a-form-item>
        <a-input-password
          size="large"
          v-decorator="['password', { rules: [$root.$validate.required, $root.$validate.letter_number, $root.$validate.pwd_len], trigger: 'blur' }]"
          placeholder="请输入新密码"
          :max-length="20"
        ></a-input-password>
      </a-form-item>

      <!-- 部门负责人 -->
      <a-form-item>
        <a-input-password
          size="large"
          v-decorator="['repassword', 
            { rules: [$root.$validate.required, {validator: compareToFirstPassword}], trigger: 'blur' }]"
          placeholder="请再输一次密码"
          :max-length="20"
        ></a-input-password>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {Modal} from 'ant-design-vue';
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: 'department'}),
      }
    },

    props: {
      handle: String,
      data: Object,
      isChild: Boolean,
    },

    components: {
      AModal: Modal,
    },

    mixins: [dialogMixins],

    methods: {

      compareToFirstPassword() {
        this.$root.$validate.confirm_pass_to_first(this.form, ...arguments);
      },

      initFields() {
        let data = this.$toFormType(this.data);

        this.form.setFieldsValue(data);
      },

      handleOk() {

        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {

            this.confirmLoading = true;

            this.$axios.ChangePassword(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success('修改成功');

                this.$emit('update:visible', false);

                this.$store.dispatch('outLogin', {
                  router: this.$router
                });
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })

          }
        });
      },

      handleCancel() {

      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
