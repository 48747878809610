export default {
  authBookTitle: 'Spark Music 音乐授权书',
  authBookSubTitle: '本项目详细授权信息',

  authBookText1: '本证书证明',
  authBookText2: '所属的',
  authBookText3: '可以在',
  authBookText4: '下合法使用',
  authBookText5: '该音乐的授权期限为：',
  authBookText6: '永久，',
  authBookText7: '授权地域为：',

  authBookListGoodName: '商品名称：',
  authBookListAuther: '版权所有者：',
  authBookListAuthCode: '授权代码：',
  authBookListAuthMain: '授权主体：',
  authBookListAuthProject: '授权项目：',
  authBookListAuthScene: '授权场景：',
  authBookListChannel: '投放渠道：',
  authBookListAuthTime: '授权时间：',
  authBookListConnectTip: '如有任何疑问，请您联系：',

  authBookCompanyName: '北京火星火翎文化科技有限公司',
}