<template>
  <div class="common-page">
    <div v-if="title" class="main-tit flex cen-space">
      <div class="header-left">
        <span>{{title}}</span>
        <span class="sub-title">{{subTitle}}</span>
      </div>

      <div class="header-right">
        <slot name="right"></slot>
      </div>
    </div>
    <div :class="{'mg40':isSeparate}" class="mian-wrap flex flex-column cen-center">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
    props: {
      title: {
        type: String,
        required: false,
        default: ""
      },

      // title和main 是否分离
      isSeparate: {
        type: Boolean,
        required: false,
        default: false
      },

      subTitle: String
    }
  };
</script>
<style lang="scss" scoped>
  .common-page {
    width: 100%;
    .main-tit {
      width: 100%;
      height: 40px;
      padding: 0 30px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      background-color: #fff;
    }
    .mian-wrap {
      padding: 40px 30px;
      background-color: #fff;
    }
    .mg40 {
      margin-top: $space-md;
    }
    .sub-title {
      margin-left: 10px;
      font-size: $font-size-sm;
    }

    .header-right {
      // align-self: flex-end;
    }
  }
</style>