import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  $storage
} from "@/utils/from-common";
import * as $var from '@/utils/variables'

Vue.use(VueI18n);

import messages from './langdir'

let initLang = $storage.get($var.STORAGE.LANG);

console.log($var.STORAGE.LANG)

export default new VueI18n({
  locale: initLang || $var.LangDir.ZHCN, // 设置地区
  messages, // 设置地区信息
})