import * as $func from '~/utils/function.c';
import * as $options from '~/config/options.c';

// 全局函数

export default {
  install(Vue) {

    Vue.filter('toLicenseName', $func.toLicenseName)

    Vue.filter('toSexName', $func.toSexName)

    Vue.filter('omitBank', $func.omitBank)

    Vue.filter('omitAccount', $func.omitAccount)

    Vue.filter('formatTimeBySecond', $func.formatTimeBySecond)

    Vue.filter('toIsOrNot', (value) => {
      return $func.toOptionName($options.IsOrNotOptions, value)
    })
  }
}