export default {
  name: '姓名',
  username: '用户名',
  sex: '性别',
  location: '所在地',
  area: '地区',

  realNamePlaceholder: '请输入你的真实姓名',
  actorName: '艺名',
  actorNamePlaceholder: '请输入你的艺名',
  companyName: '公司名称',
  companyPlaceholder: '请输入公司名称',
  teamName: '团体名称',
  teamPlaceholder: '请输入团体名称',
  brandName: '厂牌名称',
  brandNamePlaceholder: '请输入公司旗下厂牌名称，用顿号区隔',
  contactsName: '联系人姓名',

  certType: '证件类型',
  certTypePlaceholder: '请输入证件号码',
  uploadFrontCertTip: '上传{name}正面照',
  uploadBackCertTip: '上传{name}背面照',
  uploadIDLicenseTip: '请上传本人手持证件照正面照片，保证证件关键信息（姓名、证件号码）清晰可见',
  uploadBusinessLicenseTip: '请手持你的营业证明拍照，保证关键信息可见',

  goUploadTip: '请上传',
  uploadPro: '上传作品',
  uploadFile: '上传文件',
  uploadBL: '上传营业执照',

  businessLicence: '营业证明',

  nation: '国籍',
  nationPlaceholder: '请选择你的国家/地区',
  operationPlace: '经营地',
  operationPlaceHolder: '请选择公司所在国家/地区',
  addressPlace: '所在地',

  pleaseSelect: '请选择',

  personDescribe: '个人描述',
  personDescribePlaceholder: '请用一段话介绍你自己，帮助我们更好地了解你~',
  describe: '描述',
  companyDescribe: '公司描述',
  companyDescribePlaceholder: '请用一段话描述公司，帮助我们更好地了解你~',

  email: '邮箱',
  emailNum: '邮箱号码',
  newEmail: '邮箱',
  emailPlaceholder: '请输入邮箱',
  emailToVerifyPlaceholder: '请输入联系邮箱，用于接收审核结果',
  newEmailPlaceholder: '请输入新邮箱',


  phone: '手机号码',
  newPhone: '请输入新手机号码',
  phonePlaceholder: '请输入手机号码',
  newPhonePlaceholder: '请输入新手机号码',

  addr: '地址',
  addrInfo: '地址信息',

  code: '验证码',

  supportExpandName: '支持扩展名：{0}',

  iReadAndAgree: '我已阅读并同意{0}',

  musicianAgreement: '《SparkMusic 音乐分销协议》',
  musicBuyAgreement: '《SparkMusic 音乐购买须知》',

  btnComfirm: '确定',
  btnCancel: '取消',

}