<template>
  <!-- <span class="ant-input-affix-wrapper ng-textarea"> -->
  <span class="ant-input-affix-wrapper ng-textarea">
    <a-textarea
      v-bind="$props"
      @change="onChange"
      @blur="onBlur"
      :class="size === 'large' ? 'ant-input-lg' : ''"
    ></a-textarea>

    <span class="ant-input-suffix" v-if="maxLength && showCounter">
      <span class="count-box">{{curNumber}}/{{maxLength}}</span>
    </span>
  </span>
</template>

<script>
  import {Input} from 'ant-design-vue';

  export default {
    name: 'NgTextarea',

    data() {
      return {
      }
    },
    props: {
      showCounter: Boolean,
      ...Input.TextArea.props
    },

    computed: {
      curNumber() {
        return this.value ? this.value.length : 0;
      }
    },

    components: {

    },
    methods: {
      onChange(newVal) {
        this.$emit('change', newVal)
      },

      onBlur(newVal) {
        this.$emit('blur', newVal)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .count-box {
    color: $disable-color;
  }

  .ng-textarea {
    line-height: unset;

    textarea {
      height: 100%;
    }

    .ant-input-suffix {
      top: auto;
      bottom: 0;
      transform: translateY(-5px);
    }
  }
</style>
