<template>
  <a-select v-bind="$props" @change="triggerChange" :loading="loading">
    <template v-for="item in list">
      <a-select-option :value="item[valueName]" :key="item[valueName]">{{item[labelName]}}</a-select-option>
    </template>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';
  import formMixin from './_formMixins';

  let props = Object.assign({}, Select.props);

  delete props.open;
  delete props.loading;

  export default {
    name: 'FormSelect',
    data() {
      return {
        list: [],
        loading: false,
      }
    },

    mixins: [formMixin],

    props: {
      ...props,
      all: {
        type: Boolean,
        default: true
      },
      allName: {
        type: String,
        default: '请选择'
      },
      allValue: {
        type: [String, Number],
        default: ''
      },
      valueName: {
        type: String,
        default: 'uid'
      },
      labelName: {
        type: String,
        default: 'name'
      },
      options: Array,
      dataFunc: Function,
      params: {
        type: Object,
        default() {
          return {}
        }
      },
      immediate: Boolean
    },

    watch: {
      params: {
        handler(newVal) {
          !this.options && this.getData(newVal)
        },
        immediate: true,
        deep: true
      },

      options: {
        handler(newVal) {
          if (newVal && newVal.length > 0) {
            let options = newVal;

            options.unshift({
              [this.labelName]: this.allName,
              [this.valueName]: this.allValue
            })

            this.list = options;
          }
        },
        immediate: true
      }
    },

    methods: {
      getData(param) {
        if (typeof this.dataFunc === 'function') {
          this.loading = true;

          this.dataFunc(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let options = data.data ? data.data.list : [];

              if (this.all) {
                options.unshift({
                  [this.labelName]: this.allName,
                  [this.valueName]: this.allValue
                })
              }

              this.list = options;
            }

            this.loading = false;
          }).catch(() => {
            this.loading = false;
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
