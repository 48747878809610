<template>
  <a-input-group v-bind="$props">
    <div class="flex cen-center">
      <a-input-number
        v-model="rateArr[0]"
        :min="0"
        :max="total"
        :size="size"
        @change="onChange($event, 0)"
        :placeholder="placeHolders[0]"
        class="flex-1"
      />
      <div class="flex-0" style="width: 20px; text-align: center;">:</div>
      <a-input-number
        v-model="rateArr[1]"
        :min="0"
        :max="total"
        :size="size"
        @change="onChange($event, 1)"
        :placeholder="placeHolders[1]"
        class="flex-1"
      />
    </div>
  </a-input-group>
</template>

<script>
  import {Input} from 'ant-design-vue';

  let props = Object.assign({}, Input.Group.props);

  // delete props.open;

  export default {
    data() {
      return {
        rateArr: []
      }
    },

    watch: {
      value: {
        handler(newVal) {
          this.rateArr = newVal;
        },
        immediate: true
      }
    },

    props: {
      total: {
        type: Number,
        default: 10
      },
      autoFill: Boolean,
      placeHolders: {
        type: Array,
        default() {
          return []
        }
      },
      value: Array,
      ...props,
    },

    components: {

    },

    methods: {
      onChange(value, index) {
        let arr = this.rateArr;

        arr[index] = value;

        if (this.autoFill) {
          let total = this.total;

          arr[1 - index] = total - value;
        }

        this.rateArr = [...arr];

        this.$emit('change', this.rateArr);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
