export default {
  startTitle: '帮作品创造更多收入',
  startDescribe: '上传你的作品，让Spark来照亮前进的道路\n通过宣传和发行获得更多曝光和版税',
  enterInstance: '立即入驻',

  // enterBtnMusician: '音乐人入驻',
  // enterBtnTeam: '团体入驻',
  // enterBtnCompany: '公司入驻',

  doingTitle: '入驻成为音乐人',
  doingDescribe: '我们在全球范围内寻找有实力、有潜力的制作人、乐手、歌手用心服务每首原创音乐，发掘出作品的商业价值',

  finishedTitle: '成功提交申请',
  finishedDescribe: '您的申请结果会在3-5个工作日内发送至您的邮箱，期待合作~',

  titleBaseInfo: '基础信息',
  titleConnectInfo: '联系信息',
  titleProductionInfo: '作品信息',

  tipSubmitSuccessSupply: '已成功提交申请',

  tipAgreement: '请阅读并同意《SparkMusic 音乐分销协议》后再提交',
}