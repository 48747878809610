export default {
  title: '我的订单',

  allOrder: '全部订单',
  menuUndoPay: '待支付订单',
  menuDoningPay: '支付中订单',
  menuDone: '已支付订单',

  tableEmtpy: '暂无购买记录',

  orderStatusText: '订单状态',

  activeDiaTitle: '激活音乐授权',
  activeDiaMain: '为保证您的授权真实有效，请如实填写项目信息！',

  labelAuthType: '选择用途',
  labelAuthMain: '被授权者',
  labelAuthProject: '项目名称',
  labelContact: '联系人',
  labelContactMethod: '手机号码',
  labelAuthChannel: '投放渠道',

  orderNumber: '订单号',
  orderNumberPlaceholder: '如需多个订单合并开具发票,用逗号分隔订单号',

  pdAuthProject: '例如：XXX个人毕业设计；XXX个人账号系列视频',
  pdContactMethodOfAuth: '请输入授权对象的手机号码',
  pdContactName: '联系人姓名',
  pdAuthMain: '请输入被授权「公司全称」或「个人身份证真实姓名」',
  labelAuthChannelPlaceholder: '例如：XXX学院毕业设计展； B站、微博、小红书等社交平台',

  activeSuccess: '激活成功'
}