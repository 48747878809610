export * from '~/config/options.c'
import * as $val from '@/config/options.value.js'
import $lang from '../langs/index'

export const LoginWaysOptions = (str = '') => {
  return [{
      label: $lang.t('form.emailNum') + str,
      value: $val.LoginWay.email
    },
    {
      label: $lang.t('form.phone') + str,
      value: $val.LoginWay.phone
    }
  ]
}

// 授权选项
export const AuthOptions = [{
    label: '已授权音乐',
    value: $val.AuthStatus.did
  },
  {
    label: '未授权音乐',
    value: $val.AuthStatus.undo
  }
]