export * from '~/config/config.c'

// 测试环境和本地环境使用人声id
// let rsCateId = '75007799630b100c001';

// // 生产环境
// if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_REQUEST === 'build') {
//   rsCateId = '750079388554c010001'
// }

// 弹出框的宽度
export const DialogWidth = '640px';

// md 栅格下的屏幕宽度 
export const ScreenWidthMd = 768;

export const MusicSearchBpmRange = [40, 280];

export const MusicSearchDurationRange = [0, 5 * 60];

export const DefaultAuthCode = 'ba5266ef-8a2d-40e4-addd-9203be53f6dd';

export const EmptyImage = require('@/assets/images/empty.png');

// 人声类别ID， 目前主要用来区分表格中人声栏展示话筒icon
// export const RenShenCateId = rsCateId;