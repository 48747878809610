<template>
  <div class="ng-breadcrumb">
    <div v-if="back">
      <a-button type="link" @click="$router.go(-1)">
        <a-icon type="arrow-left"></a-icon>
        <span>返回</span>
      </a-button>
      <a-divider type="vertical" />
    </div>

    <a-breadcrumb :routes="data" separator=">">
      <template slot="itemRender" slot-scope="{ route, routes }">
        <span v-if="routes.indexOf(route) === routes.length - 1">{{ route.breadcrumbName }}</span>

        <router-link v-else :to="route.path" replace>{{ route.breadcrumbName }}</router-link>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script>
  import {Breadcrumb as ABreadcrumb, Divider as ADivider} from 'ant-design-vue';

  export default {
    name: 'NgBreadcrumb',
    
    data() {
      return {

      }
    },

    props: {
      back: {
        type: Boolean,
        default: true
      },
      data: Array
    },

    components: {
      ABreadcrumb,
      ADivider,
    },

    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .ng-breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-btn {
      padding: 0;
    }

    /deep/ .anticon {
      color: #878e99;
    }

    /deep/ .ant-breadcrumb > span:last-child {
      font-weight: $font-weight-bold;
    }
  }
</style>
