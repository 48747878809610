import {
  Axios
} from '@/utils/from-common';

// 登录
export const Login = (param) => Axios('POST', '/web/user/login', param);

// 注册
export const Register = (param) => Axios('POST', '/web/user/reg', param);

// 找回密码
export const FindPwd = (param) => Axios('POST', '/web/user/pwd', param);

// 设置新密码
export const newPwd = (param) => Axios('POST', '/web/user/newPwd', param);