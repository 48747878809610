<template>
  <a-select v-bind="$props" @change="triggerChange">
    <template v-for="item in list">
      <a-select-option :value="item[valueName]" :key="item[valueName]">{{item.name}}</a-select-option>
    </template>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';
  import formMixin from './_formMixins';
  import {OpenAndCloseStatus} from '@/config/options'

  let props = Object.assign({}, Select.props);

  delete props.open;

  export default {
    data() {
      return {
        list: [],
        valueName: 'id',
      }
    },

    mounted() {
      let options = [...OpenAndCloseStatus];

      if (this.all) {
        options.unshift({
          name: '全部',
          [this.valueName]: ''
        })
      }

      this.list = options;
    },

    mixins: [formMixin],

    props: {
      ...props,
      all: {
        type: Boolean,
        default: true
      }
    },

    components: {

    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
