<template>
  <div class="ng-card flex cen-space w-100" :class="handleTransition ? 'transition' : ''">
    <div class="centent">
      <slot></slot>
    </div>
    <div class="handle">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    props: {
      handleTransition: {
        type: Boolean,
        default: true
      }
    },
    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .ng-card {
    padding: 10px 20px;
    border: 1px solid #dddddd;
    margin-bottom: 20px;
    box-shadow: 0 0 0 #dddddd;
    transition: all linear 0.1s;
    cursor: default;

    &:hover {
      box-shadow: 5px 5px 10px #dddddd, -1px -1px 5px #dddddd;
    }

    .handle {
      padding-left: 15px;

      .ant-btn.ant-btn-link {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &.transition {
      .handle {
        visibility: hidden;
      }

      &:hover {
        .handle {
          visibility: visible;
        }
      }
    }
  }
</style>
