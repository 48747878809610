import {
  $storage
} from "@/utils/from-common";
import * as $var from '@/utils/variables';
import {
  UserInfo,
  GoodsNumOfCartApi
} from '@/api/index'
import {
  Modal
} from 'ant-design-vue';

export default {
  // 登录成功后需要保存的一些数据
  inLogin(context, data) {
    let index = context.state.rootKeyIndex;

    context.commit('changeLogin', true);
    context.commit('changeUserInfo', data);
    context.commit('changeRootKeyIndex', ++index);
    context.dispatch('updateGoodNumOfCart');
    $storage.set($var.STORAGE.TOKEN, data.token);
    $storage.set($var.STORAGE.USERINFO, data);
  },

  // 退出登录
  outLogin(context, data = {}) {
    $storage.remove($var.STORAGE.TOKEN);
    $storage.remove($var.STORAGE.USERINFO);
    $storage.remove($var.STORAGE.AUTHLIST);

    context.commit('changeLogin', false);
    context.commit('changeUserInfo', {});
    context.commit('changeGoodNumOfCart', 0);

    data && data.router && data.router.replace && data.router.replace('/login');
  },

  // 先提示，后点击确定退出登录
  checkOutLogin(context, data) {
    Modal.confirm({
      title: data.title || '是否退出登录？',
      content: data.content || '',
      onOk() {
        context.dispatch('outLogin', {
          ...data
        })
      }
    })
  },

  // 判断登录
  checkLogin(context) {
    let token = $storage.get($var.STORAGE.TOKEN);

    if (token) {
      context.commit('changeLogin', true);
    }
  },

  // {
  //     UserInfo().then(res => {
  //       let data = res.data;

  //       if (data && data.code.toString() === '0') {
  //         context.commit('changeUserInfo', data.data);
  //         $storage.set($var.STORAGE.USERINFO, data.data);
  //       }
  //     })
  // }

  // 获取用户信息
  getUserInfo(context, request) {
    if (context.state.login) {
      let userinfo = $storage.get($var.STORAGE.USERINFO);

      if (userinfo && !request) {
        context.commit('changeUserInfo', userinfo);
      } else {
        UserInfo().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            $storage.set($var.STORAGE.USERINFO, data.data);
            context.commit('changeUserInfo', data.data);
          }
        })
      }
    }
  },

  checkUserInfo(context) {
    let userinfo = $storage.get($var.STORAGE.USERINFO);

    if (userinfo) {
      context.commit('changeUserInfo', userinfo);
    }
  },

  updateUserInfo(context, userinfo) {
    if (userinfo) {
      context.commit('changeUserInfo', userinfo);
      $storage.set($var.STORAGE.USERINFO, userinfo);
    }
  },

  updateGoodNumOfCart(context) {
    GoodsNumOfCartApi().then(res => {
      const data = res.data;

      if (data && data.code == 0) {
        context.commit('changeGoodNumOfCart', data.data ? data.data.total : 0);
      }
    })
  },

}