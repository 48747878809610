<template>
  <a-select v-bind="$props" @change="triggerChange">
    <template v-for="item in list">
      <a-select-option :value="item.role_id" :key="item.role_id">{{item.name}}</a-select-option>
    </template>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';
  import formMixin from './_formMixins';

  let props = Object.assign({}, Select.props);

  delete props.open;

  export default {
    data() {
      return {
        list: []
      }
    },

    mixins: [formMixin],

    props: {
      ...props,
      deptId: {
        type: [Number, String],
        default: ''
      },
      all: {
        type: Boolean,
        default: true
      }
    },

    created() {
      this.getData();
    },

    methods: {
      getData() {
        this.$axios.AllOpenRoles({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let options = data.data ? data.data.list : [];

            if (this.all) {
              options.unshift({
                name: '请选择',
                role_id: ''
              })
            }

            this.list = options;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
