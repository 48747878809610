// 授权场景
export const AuthScene = {
  scene1: '0', // 全场景
  scene2: '1', // 连续剧/综艺/动画片
  scene3: '2', // 商业广告/宣传片
  scene4: '3', // 自媒体视频
  scene5: '4', // 电影
  scene6: '5', // 游戏/内置音乐
  scene7: '6', // 纪录片
  scene8: '7', // 网课/直播
  scene9: '8', // 线下公播
  scene10: '9', // 个人用途(非商用)
  scene11: '10', //  播剧/有声读物
}

// 音乐网站
export const MusicianWebs = {
  AudioJungle: '1', // 'AudioJungle'
  Douban: '2', // '豆瓣音乐'
  Bandcamp: '3', // 'Bandcamp'
  Spotify: '4', // 'Spotify'
  SoundCloud: '5', // 'SoundCloud'
  AppleMusic: '6', // 'Apple-Music'
  Tencent: '7', // '腾讯音乐'
  NetEase: '8', // '网易云音乐'
  Xiami: '9', // '虾米音乐'
  Person: '10', // '个人网站'
}

// 系统来源
export const SystemOrigin = {
  Admin: '1', // 运营管理
  Supplier: '2', // 供应商系统
  Web: '3', // 官网
}

// 行为类型
// 1 收藏, 2 分享, 3 试听, 4 下载
export const DeedType = {
  collect: '1',
  share: '2',
  tryListen: '3',
  download: '4',
}

// 行为对象 1 音乐, 2 歌单, 3 音乐人 如：收藏歌单、收藏音乐
export const ShareAndCollectType = {
  music: '1', // 音乐
  songlist: '2', // 歌单
  supplier: '3', // 音乐人
}

// 标注类型
export const MarkTargetVal = {
  music: '1', // 音乐
  songlist: '2', // 歌单
  proposal: '3', // 提案
  musician: '4', // 音乐人
}

// 音乐质量
export const MusicQuality = {
  high: '2', // 高清
  try: '1', // 试听
}

// 行为对象 1 音乐, 2 歌单, 3 音乐人
// export const TargetOfDeeds = {
//   music: '1',
//   songlist: '2',
//   songer: '3'
// }

// 支付方式
export const PayType = {
  wx: '1',
  zfb: '2',
  gdg: '3', // 公对公
}

// 提现状态
export const DrawingStatus = {
  waitAudit: '1', // 待审核
  cancelDraw: '2', // 取消提现
  waitTransfer: '3', // 待转账
  didDraw: '4', // 已转账
}

// 支付方式 value值
export const SettleWayValues = {
  Alipay: '1', // 支付宝
  PayPal: '2',
  Payoneer: '3',
  CToC: '4', // 公对公付款
}

// 音效  1-动作，2-环境，3-人声
export const MusicEffect = {
  ACTION: '1', // 动作
  ENVIROMENT: '2', // 环境
  SOUNDHUMAN: '3', // 人声
  WHITE: '4', // 白噪音
}

// 音乐类型
export const MusicType = {
  RS: '1', // 人声
  BGM: '2', // bgm
  YX: '3', // 音效
}

// 搜索排序 音乐 歌单
export const SearchFilterType = {
  last: '0', // 最新
  hot: '1', // 热门
  recommend: '2', // 推荐
}

// 供应商类型
export const SupplierTypeValue = {
  MUSICIAN: '1',
  TEAM: '2',
  COMPANY: '3'
}

// 签约状态 0-未签约, 1-已签约, 2-已过期
export const SignStatus = {
  undo: '0',
  done: '1',
  expire: '2'
}

// 发送验证码 默认值
export const SendCodeDefaultValue = {
  phone: '1', // 短信
  email: '1', // 邮件
}

// 发票抬头类型
export const ReceiptTitleType = {
  company: '1',
  person: '2',
}

// 发票类型
export const ReceiptType = {
  common: '1', // 纸质版增值税普通发票
  special: '2', // 纸质版增值税专用发票
  commonEelec: '3', // 电子版增值税普通发票
  Eelec: '4', // 数电普票
  specialEelec: '5', // 数电专票
}

// 激活音乐授权类型
export const ActiviateMusicAuthType = {
  company: '1',
  person: '2',
}

// 授权期限
export const AuthPeriod = {
  forever: '2', // 永久
  year: '1', // 一年
}

// 授权期限
export const AuthArea = {
  chinaLand: '1', // 中国-大陆
  chinaAll: '2', // 中国（含港澳台）
  global: '3', // 全球
}

// 是否
export const CommonCheck = {
  correct: '1',
  fail: '0'
}

// 订单类型
export const orderType = {
  music: '1',
  package: '2'
}

// 商品类型
export const goodsType = {
  music: '1',
  package: '2', // 商品
}

// 订单 状态 (用来搜索，不是订单的状态)
export const OrderStatus = {
  undoPay: '0', // 未支付,
  doingPay: '1', // 支付中
  didPay: '2', // 已支付
}

// 审核状态
export const CheckStatus = {
  checking: '-1', // 审核中
  reject: '0', // 已拒绝
  agree: '1', // 已通过
}

// 未支付订单 类型
export const UndoPayOrderType = {
  wait: '0', // 待支付, 
  overtime: '1', // 已超时, 
  cancel: '2', // 已取消, 
  paying: '3', // 支付中
  payed: '4', // 已支付 
  drop: '5', // 已下架
  error: '6', // 支付失败, 
}

// 已支付订单 类型
export const DidPayOrderType = {
  undoAuth: '0', // 未授权,
  didAuth: '1', // 已授权
}

// 订单渠道
export const OrderChannel = {
  spark: '1',
  paixin:'3',
  offline: '4',
}

// 售卖状态
export const SaleStatus = {
  wait: '-1', // 待上架
  online: '1', // 上架
  offline: '0', // 下架
}