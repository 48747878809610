import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';

Vue.use(Vuex);

// state
let state = {
  login: false, // 是否登录

  userInfo: {}, // 用户信息

  rootKeyIndex: 0,

  /**
   * 加载中的触发变量，需要设置到需要加载中的位置
   * 
   * 为了处理跨多级组件不方便调用的情况
   */
  contentLoading: false, // 全局加载中页面
  showMusicDetailDialog: false, // 弹出音乐细节弹出框， 加入购物车...
  showMusicDownload: false, // 弹出音乐下载

  showAuthPackageDialog: false, // 显示授权框
  currentAuthPackageInfo: {}, // 弹出下载狂去立即授权需要传入的数据

  currentMusicDetail: {}, // 当前音乐信息 加入购物车..
  currentDownloadMusicDetail: {}, // 下载音乐信息

  globalVideoPlaying: false, // 全局播放音频
  globalVideoInfo: {}, // 播放音频信息（url，name）
  globalVideoList: [], // 播放音频列表
  globalVideoPlayProgress:null,// 播放音频进度相关信息
  globalVideoPlayProgressSelf:null,// 播放音频进度相关信息

  // 登录弹窗
  loginAndRegister: false,

  goodNumOfCart: 0, // 购物车中商品数量
  contactUsDialog: false, // 显示联系我们
  videoDialog: false,
  historyMusicDialog:false,
  showReister:false,
}

// mutations
let mutations = {
  changeLogin(state, value) {
    state.login = value;
  },

  changeUserInfo(state, value) {
    value && (state.userInfo = value);
  },

  changeContentLoading(state, value) {
    state.contentLoading = !!value;
  },

  changeVideoPlaying(state, value) {
    state.globalVideoPlaying = value;
  },

  changeVideoInfo(state, value) {
    state.globalVideoInfo = value;
  },
  changeVideoPlayProgress(state, value) {
    state.globalVideoPlayProgress = value;
  },
  changeVideoPlayProgressSelf(state, value) {
    state.globalVideoPlayProgressSelf = value;
  },
  changeVideoList(state, value) {
    state.globalVideoList = value;
  },

  changeMusicDetailDialog(state, value) {
    state.showMusicDetailDialog = value;
  },

  changeMusicDownloadDialog(state, value) {
    state.showMusicDownload = value;
  },

  changeAuthPackageDialog(state, value) {
    state.showAuthPackageDialog = value;
  },

  changeAuthPackageInfo(state, value) {
    state.currentAuthPackageInfo = value;
  },

  changeCurrentMusicDetail(state, value) {
    state.currentMusicDetail = value;
  },

  changeCurrentDownloadMusicDetail(state, value) {
    state.currentDownloadMusicDetail = value;
  },

  changeLoginAndRegisterDialog(state, value) {
    state.loginAndRegister = value;
  },

  changeGoodNumOfCart(state, value) {
    state.goodNumOfCart = value;
  },
  changeContactUsDialog(state, value) {
    state.contactUsDialog = value;
  },
  changeRootKeyIndex(state, value) {
    state.rootKeyIndex = value;
  },

  changeVideoDialog(state, value) {
    state.videoDialog = value;
  },
  changeHistoryMusicDialog(state, value) {
    state.historyMusicDialog = value;
  },
  changeShowRegister(state, value) {
    state.showReister = value;
  },
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
})