<script>
  import {Checkbox} from 'ant-design-vue'

  const ChildName_Default = 'child';
  const Common_Quota = `'`;

  const changeDataItem = function (keysDir, item, isDelOrigin = false) {
    let entries = Object.entries(keysDir);

    item = {...item}

    if (entries && entries.length > 0) {
      entries.forEach(([key, value]) => {
        item[key] = item[value];

        isDelOrigin && delete item[value];
      })
    }

    return item;
  }

  const joinIndexName = function (list) {
    if (list && list.length > 0) {
      return `[${list.join('][')}]`;
    }

    return ''
  }

  export default {
    data() {
      return {
        /**
         * 本插件使用的字段
         * child label value
         */
        newList: []
      }
    },

    render() {
      let {onChange, disabled} = this, indexArr = [];

      return <div class="checkbox-root">{one(this.newList, indexArr)}</div>;

      function one(list) {
        return list.map((item, index) => {
          let hasChild = item.child && item.child.length > 0;

          indexArr.push(index);

          let arr = [...indexArr];

          let parEle = [<a-checkbox value={item.value} disabled={disabled} onChange={(e) => {onChange(e, arr)}}>{item.label}</a-checkbox>];
          // let parEle = [<a-checkbox indeterminate={item.indet} checked={item.checked} value={item.value} disabled={disabled} onChange={(e) => {onChange(e, arr)}}>{item.label}</a-checkbox>];

          hasChild && parEle.push((<div class="checkbox-parent">{one(item.child)}</div>))

          indexArr.pop();

          return parEle;
        })
      }
    },

    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      },
      fieldNames: {
        type: Object,
        default() {
          return {}
        }
      },
      value: Array,
      defaultValue: Array,
      disabled: Boolean
    },

    watch: {
      list: {
        handler(newVal) {
          this.newList = this.handData(newVal);
        },
        immediate: true
      },

      defaultValue: {
        handler(newVal) {
          if (newVal && newVal.length > 0) {
            this.$nextTick(() => {
              let newList = this.setDefaultValue(this.newList, newVal);

              this.newList = [...newList];
            })
          }
        },
        immediate: true
      }
    },

    components: {
      ACheckbox: Checkbox
    },

    mounted() {
      // this.initDefault();
    },

    methods: {
      onChange(event, indexArr) {
        let target = event.target;
        // eslint-disable-next-line
        let checked = target.checked;
        let indexName = this.getIndexName(indexArr);

        try {
          // 设置对应的多选框选中或取消
          eval(`( this.$set(this.newList${indexName}, 'indet', false) )`)
          eval(`( this.$set(this.newList${indexName}, 'checked', checked) )`)
        } catch (error) {
          this.$error(error);
        }

        this.setUpAndDownChecked(this.newList, indexName, checked);

        let checkedArr = this.getCheckedValue(this.newList);

        this.$emit('change', checkedArr);
      },

      // 设置当前位置向下和向上 样式
      setUpAndDownChecked(list, indexName, checked, childKey = 'child') {
        let isTargetAndChild = false, indexArr = [], that = this;

        one(list);

        function one(cList) {
          cList.forEach((item, index) => {
            indexArr.push(index);

            // 如果当前是目标位置，那么此位置下的所有子元素都会设置选中或取消
            let curIndexPathStr = joinIndexName(indexArr);
            let isCurtarget = curIndexPathStr === indexName;

            // 设置所有子元素全部选中或取消
            if (isTargetAndChild) that.$set(item, 'checked', checked);

            isCurtarget && (isTargetAndChild = true);

            if (item.child && item.child.length > 0) {
              let isRoot = !indexName.indexOf(curIndexPathStr);

              indexArr.push(`${Common_Quota}${childKey}${Common_Quota}`);

              one(item.child);

              // 设置同一路径上的父位置状态
              if (!isTargetAndChild && isRoot) {
                that.setUpChecked(item, checked);
              }

              indexArr.pop();
            }

            isCurtarget && (isTargetAndChild = false);
            indexArr.pop();
          })
        }
      },

      // 向上设置选中、取消或样式
      setUpChecked(item) {
        let isChecked = item.child.every(item => item.checked);
        let isIndet = item.child.some(item => item.checked || item.indet);

        if (isChecked) {
          this.$set(item, 'indet', false)
          this.$set(item, 'checked', true)
        } else if (isIndet) {
          this.$set(item, 'indet', isIndet)
        } else {
          this.$set(item, 'indet', false)
          this.$set(item, 'checked', false)
        }
      },

      getCheckedValue(list, key_name = 'value') {
        let finalArr = [];

        one(list);

        return finalArr;

        function one(nlist) {
          return nlist.map(item => {
            if (item.child && item.child.length > 0) {
              one(item.child);
            } else {
              item.checked && finalArr.push(item[key_name]);
            }
          })
        }
      },

      setDefaultValue(list, checkedArr, key_name = 'value') {
        let that = this;

        return one(list);

        function one(nlist) {
          return nlist.map(item => {
            if (item.child && item.child.length > 0) {
              item.child = one(item.child);
              that.setUpChecked(item);
              return item;
            } else {
              let isChecked = !!~checkedArr.indexOf(item[key_name]);

              return isChecked ? Object.assign({}, item, {checked: isChecked}) : item;
            }
          })
        }
      },

      getIndexName(arr = [], childKey = 'child') {
        let len = arr.length;

        return arr.reduce((prev, next, index) => {
          let common = prev ? `${prev}[${next}]` : `[${next}]`;

          return index < len - 1 ? `${common}[${Common_Quota}${childKey}${Common_Quota}]` : `${common}`;
        }, '')
      },

      // 转化数据格式
      handData(list) {
        let names = {...this.fieldNames}, childKey = names.child || ChildName_Default;

        delete names.child;

        if (!names || Object.keys(names).length === 0) return [...list];

        return one([...list]);

        function one(nlist) {
          let clist = nlist.map(item => {
            if (item && item[childKey] && item[childKey].length > 0) {
              item.child = one([...item[childKey]]);

              childKey !== ChildName_Default && delete item[childKey];
            }

            return changeDataItem(names, item, true)
          })

          return clist
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .checkbox-root {
    .checkbox-parent {
      padding-left: 24px;
    }

    .ant-checkbox-group {
      line-height: 40px;
    }

    /deep/ {
      .ant-checkbox-wrapper {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
</style>
