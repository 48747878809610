//加法   
export const add = function () {
  let [r1 = 0, r2 = 0] = arguments, m;

  r1 = r1.toString();
  r2 = r2.toString();

  let s1 = r1.split(".")[1];
  let s2 = r2.split(".")[1];

  let len1 = s1 ? s1.length : 0
  let len2 = s2 ? s2.length : 0

  m = Math.pow(10, Math.max(len1, len2))

  return (r1 * m + r2 * m) / m
}

//减法   
export const sub = function () {
  let [r1 = 0, r2 = 0] = arguments;
  return add(r1, -r2);
}

//乘法   
export const mul = function () {
  let [r1 = '0', r2 = '0'] = arguments, m = 0;

  r1 = r1.toString();
  r2 = r2.toString();

  let s1 = r1.split(".")[1];
  let s2 = r2.split(".")[1];

  m += s1 ? s1.length : 0
  m += s2 ? s2.length : 0

  return Number(r1.replace(".", "")) * Number(r2.replace(".", "")) / Math.pow(10, m)
}

//除法   
export const div = function () {
  let [r1 = '0', r2 = '0'] = arguments;

  r1 = r1.toString();
  r2 = r2.toString();

  let r1Arr = r1.split(".");
  let r2Arr = r2.split('.');

  let len1 = r1Arr.length > 1 ? r1Arr[1].length : 0;
  let len2 = r2Arr.length > 1 ? r2Arr[1].length : 0;

  r1 = Number(r1.replace(".", ""))
  r2 = Number(r2.replace(".", ""))

  return (r1 / r2) * Math.pow(10, len2 - len1);
}