export default {
  data() {
    return {
      uploadParams: {},
      uploadName: 'file',
    }
  },

  methods: {
    onUploading(uploading) {
      this.uploading = uploading;
    },
  }
}