// const Pay = () => import('@/pages/pay/Pay.vue');
const PayMusic = () => import('@/pages/pay/PayMusic.vue');
const PayPackage = () => import('@/pages/pay/PayPackage.vue');
const PaySuccess = () => import('@/pages/pay/PaySuccess.vue');
const Paying = () => import('@/pages/pay/Paying.vue');

export default [{
    path: '/paymusic/:order_id',
    name: 'paymusic',
    meta: {
      isLogin: true
    },
    component: PayMusic
  },
  // {
  //   path: '/paypackage/:order_id',
  //   name: 'paypackage',
  //   meta: {
  //     isLogin: true
  //   },
  //   component: PayPackage
  // },
  {
    path: '/paypackage/:order_id',
    name: 'paypackage',
    meta: {
      isLogin: true
    },
    component: PayPackage
  },
  {
    path: '/payresult/success/:order_id',
    name: 'paysuccess',
    meta: {
      isLogin: true
    },
    component: PaySuccess
  },
  {
    path: '/payresult/paying/:order_id',
    name: 'paying',
    meta: {
      isLogin: true
    },
    component: Paying
  }
]