import Axios from '~/utils/axios.js';
import * as $config from '~/config/config.c';

let baseURL = $config.ServeBaseHost;

// 获取上传文件配置
export const UploadConfig = (param) => Axios('POST', '/upload/config', param);

// 上传文件(服务器转发到oss)
export const UploadFile = `${baseURL}/upload/file`;

// 获取手机号码归属地
export const PhoneCodeArea = (param) => Axios('POST', '/address/pcodeSelector', param);

// 全部国家和地区
export const CountryArea = (param) => Axios('POST', '/address/selector', param);

// 一级国家和地区
export const CountryAreaTop = (param) => Axios('POST', '/address/topSelector', param);

// 发送通用手机验证码
export const SendCommonPhoneCode = (param) => Axios('POST', '/captcha/sms', param);

// 发送通用邮箱验证码
export const SendCommonEmailCode = (param) => Axios('POST', '/captcha/email', param);

// 获取音频文件波形图数据
export const GetAudioWaveForm = (music_id) => Axios('GET', `/web/music/waveform/${music_id}`);