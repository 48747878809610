<template>
  <a-select v-bind="$props" @change="triggerChange" :loading="loading">
    <template v-for="item in list">
      <a-select-option :value="item.id" :key="item.id">{{item.value || item.cn}}</a-select-option>
    </template>
  </a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';
  import formMixin from './_formMixins';

  let props = Object.assign({}, Select.props);

  delete props.open;
  delete props.loading;

  export default {
    data() {
      return {
        list: [],
        loading: false
      }
    },

    mixins: [formMixin],

    props: {
      /**
       *  展示方式
       *  code 只展示区号 + 86
       *  text 只展示文本 中国
       *  both 展示区号和文本 中国 + 86
       *  both-en 展示区号和文本 CN + 86
       */
      showType: {
        type: String,
        default: 'text',
      },
      ...props
    },

    created() {
      this.getData();
    },

    components: {

    },

    methods: {
      handleData(data, type) {
        return data.map(item => {
          switch (type) {
            case 'code':
              item.value = `+ ${item.prefix}`;
              break;
            case 'text':
              item.value = item.cn;
              break;
            case 'both':
              item.value = `${item.cn} + ${item.prefix}`;
              break;
            case 'both-en':
              item.value = `${item.en} + ${item.prefix}`;
              break;
          }

          return item;
        })
      },

      getData() {
        let phoneCodeArea = this.$root.$PhoneCodeArea;

        if (phoneCodeArea && phoneCodeArea.length > 0) {
          this.list = phoneCodeArea;
        } else {
          this.loading = true;

          this.$axios.PhoneCodeArea({}).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.list = this.handleData(data.data ? data.data.list : [], this.showType);
              this.$root.$PhoneCodeArea = this.list;
            }

            this.loading = false;
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
