import { valueLang } from './valueLang'
/**
 * 表单验证
 */
const validateMsg = process.env.VUE_APP_TYPE === 'supplier_en' ? valueLang.en.validate : valueLang.zh_CN.validate

// 不可为空
export const required = {
  required: true,
  message: validateMsg['message1']
};

// 多选框之类的必填
export const checkRequired = {
  required: true,
  message: validateMsg['message2']
};

// 请上传
export const uploadRequired = {
  required: true,
  message: validateMsg['message3']
};

// 必填
export const requiredFunc = (message = validateMsg['message1']) => {
  return {
    required: true,
    message
  }
}

// 手机号
export const phone = {
  pattern: /^1[3456789]\d{9}$/,
  message: validateMsg['message4']
}

// 邮箱
export const email = {
  // pattern: /^[0-9a-zA-Z]+([.\-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[-_]*[0-9a-zA-Z]+\.)+[0-9a-zA-Z]{2,6}$/,
  pattern: /@/,
  message: validateMsg['message5']
}

// 邮箱或手机
export const phoneOrEmail = {
  // pattern: /^[0-9a-zA-Z]+([.\-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[-_]*[0-9a-zA-Z]+\.)+[0-9a-zA-Z]{2,6}$/,
  pattern: /(^1[3456789]\d{9}$)|(@)/,
  message: validateMsg['message6']
}

// 必须大于0
export const greater_zero = {
  // pattern: /^[0-9a-zA-Z]+([.\-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[-_]*[0-9a-zA-Z]+\.)+[0-9a-zA-Z]{2,6}$/,
  pattern: /^[1-9]/,
  message: validateMsg['message7']
}

// 中英文及数字
export const letter_zh_number = {
  pattern: /^[\u4e00-\u9fa50-9a-zA-Z]*$/,
  message: validateMsg['message8']
}

// 字母和数字
export const letter_number = {
  pattern: /^[\da-zA-Z]*$/,
  message: validateMsg['message9']
}

// 中英文及数字 下划线空格
export const letter_zh_number_char = {
  pattern: /^[\u4e00-\u9fa50-9a-zA-Z_\s]*$/,
  message: validateMsg['message10']
}

// 厂牌名称 中英文及数字 下划线空格还有顿号
export const brand_name = {
  pattern: /^[\u4e00-\u9fa50-9a-zA-Z_\s、]*$/,
  message: validateMsg['message10']
}

// 密码长度
export const pwd_len = {
  pattern: /^[^%]{8,20}$/,
  message: validateMsg['message11']
}

// 验证确认密码 与第一次比较(需结合验证方法)
export const confirm_pass_content = validateMsg['message12'];

// 验证确认密码 与第一次比较
export const confirm_pass_to_first = function (form, rule, value, callback) {
  if (value && value !== form.getFieldValue('password')) {
    callback(confirm_pass_content);
  } else {
    callback();
  }
}
