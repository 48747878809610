import {
  STORAGE
} from '~/utils/variables.c.js'

class Storage {
  constructor(props) {
    this.props = props || {};

    this.source = this.props.source || window.localStorage;

    this.save_keys = this.props.save_keys || []; // 当存储满溢时需要保存的字段

    this.initRun();
  }

  /*
   * set 存储方法
   * @ param {String}     key 键
   * @ param {String}     value 值，
   * @ param {String}     expired 过期时间，以分钟为单位，非必须
   */
  set(key, value, expired) {
    let source = this.source;

    try {
      source[key] = JSON.stringify(value);

      expired && (source[`${key}__expires__`] = Date.now() + 1000 * 60 * expired);

    } catch (e) {

      if (this.isQuotaExceeded(e)) {
        for (let key in source) {
          this.save_keys.indexOf(key) === -1 && this.remove(key);
        }

        // eslint-disable-next-line
        console.error('本地存储超过限制');
      } else {
        // eslint-disable-next-line
        console.error('保存到本地存储失败');
      }
    }

    return value;
  }

  /*
   * get 获取方法
   * @ param {String}     key 键
   * @ param {String}     expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
   */
  get(key) {
    const now = Date.now();
    const source = this.source,
      expired = source[`${key}__expires__`] || now + 1;

    if (now >= expired) {
      this.remove(key);
      return;
    }

    let value = source[key];

    try {
      value = source[key] ? JSON.parse(source[key]) : source[key];
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }

    return value;
  }

  remove(key) {
    const data = this.source,
      value = data[key];

    delete data[key];
    delete data[`${key}__expires__`];

    return value;
  }

  /**
   * 是否溢出
   */
  isQuotaExceeded(e) {
    var quotaExceeded = false;

    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            quotaExceeded = true;
            break;
          case 1014: // Firefox 
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true;
            }
            break;
        }
      } else if (e.number === -2147024882) { // IE8 
        quotaExceeded = true;
      }
    }

    return quotaExceeded;
  }

  initRun() {
    const reg = new RegExp("__expires__");
    let data = this.source;
    let list = Object.keys(data);

    if (list.length > 0) {
      list.map((key) => {
        if (!reg.test(key)) {
          let now = Date.now();
          let expires = data[`${key}__expires__`] || Date.now + 1;

          if (now >= expires) {
            this.remove(key);
          }
        }

        return key;
      });
    }
  }
}

export {
  Storage
};

export default new Storage({
  save_keys: [STORAGE.USER, STORAGE.TOKEN]
});