<template>
  <a-input-group class="min-max" :compact="compact">
    <a-input-number
      v-bind="$props"
      :value="newValue[0]"
      @change="(value) => {onChange(value, Indexs.min)}"
      @blur="(e) => {onBlur(e, Indexs.min)}"
      @keyup.enter="(e) => {e.target && e.target.blur()}"
      :placeholder="placeholders[Indexs.min]"
    />

    <span class="seperate">-</span>

    <a-input-number
      v-bind="$props"
      :value="newValue[1]"
      @change="(value) => {onChange(value, Indexs.max)}"
      @blur="(e) => {onBlur(e, Indexs.max)}"
      @keyup.enter="(e) => {e.target && e.target.blur()}"
      :placeholder="placeholders[Indexs.max]"
    />
  </a-input-group>
</template>

<script>
  import {Input, InputNumber} from 'ant-design-vue';

  let props = Object.assign({}, InputNumber.props);
  let groupProps = Object.assign({}, Input.Group.props);

  delete props.value
  delete props.placeholder

  const Indexs = {
    min: 0,
    max: 1,
  }

  export default {
    name: 'FormMinMax',
    data() {
      return {
        Indexs
      }
    },
    props: {
      ...props,
      ...groupProps,
      filedNames: Array,
      value: String,
      placeholders: {
        type: Array,
        default() {
          return ['最小值', '最大值']
        }
      }
    },
    computed: {
      newValue() {
        return this.value ? this.value.split(',') : ['', ''];
      }
    },
    components: {

    },
    methods: {
      onChange(curVal, index) {
        let newVal = [...this.newValue];

        newVal[index] = curVal;

        this.$emit('change', newVal.join(','))
      },

      onBlur(event, index) {
        let target = event.target;
        let curVal = target.value;
        let newVal = [...this.newValue];

        newVal[index] = curVal;

        this.$emit('blur', newVal.join(','))
      },

      onEnter() {
        console.log('enter');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .min-max {
    display: flex !important;
    align-items: center;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    border: 1px solid #d9d9d9;

    &:focus {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .seperate {
      padding: 0 10px;
    }

    /deep/ {
      .ant-input-number {
        border-color: transparent;

        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
</style>
