<template>
  <a-cascader
    v-bind="$props"
    :options="list"
    :fieldNames="fieldNames_inner"
    @change="triggerChange"
    v-model="curValue"
    :allowClear="false"
  />
</template>

<script>
  import {Cascader as ACascader} from 'ant-design-vue';

  let props = Object.assign({}, ACascader.props);

  delete props.popupVisible;
  delete props.value;

  export default {
    data() {
      return {
        list: [],
        fieldNames_inner: {
          label: 'name', value: 'dept_id', children: 'child'
        },
        curValue: [],
      }
    },

    watch: {
      value: {
        handler(newVal) {
          this.curValue = this.$getAllValueAccrodingFinal(this.list, newVal, 'dept_id');
        },
      },
    },

    components: {
      ACascader
    },

    props: {
      all: Boolean,
      value: [String, Number],
      ...props,
    },

    created() {
      this.getDepartmentList().then(() => {
        this.curValue = this.$getAllValueAccrodingFinal(this.list, this.value, 'dept_id');
      });
    },

    methods: {
      getDepartmentList(params) {
        return new Promise(resolve => {

          if (this.list && this.list.length > 0) {
            return;
          }

          this.list.loading = true;

          this.$axios.DepartmentList(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let list = data.data && data.data.list ? data.data.list : [];

              this.all && list.unshift({
                name: '全部',
                dept_id: '0',
              })

              this.list = list;

              resolve(list);
            }
          })
        })
      },

      triggerChange(changedValue) {
        this.$emit('change', changedValue.slice(-1)[0]);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>