<template>
  <div class="ng-info-title">
    <h2 class="title">{{title}}</h2>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NgDetail',
    data() {
      return {

      }
    },
    props: {
      title: String
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .ng-info-title {
    .title {
      font-weight: bold;
      font-size: 18px;
    }

    .content {
      padding: 10px 20px;
    }
  }
</style>
