const Order = () => import('@/pages/order/Order.vue');
const Order_v2 = () => import('@/pages/order/Order_v2.vue');
const Pay = () => import('@/pages/order/Pay.vue');
const PayResult = () => import('@/pages/order/PayResult.vue');

export default [{
    path: '/order/:order_id',
    name: 'order',
    meta: {
        isLogin: true
    },
    component: Order
},
{
    path: '/order_v2/:order_id/:type',
    name: 'order_v2',
    meta: {
        isLogin: true
    },
    component: Order_v2
},
{
    path: '/pay/:type',
    name: 'pay',
    meta: {
        isLogin: true
    },
    component: Pay
},
{
    path: '/payresult/:order_id',
    name: 'payresult',
    meta: {
        isLogin: true
    },
    component: PayResult
}
]