// 全局函数
import * as globalFunction from '@/utils/function';
import globalPlugins from '~/plugins/index.c.js'

export default {
  install(Vue) {
    Vue.prototype.$deleteItem = globalFunction.deleteItem;

    Vue.prototype.$changeDataItem = globalFunction.changeDataItem;

    Vue.prototype.$getItemWithValue = globalFunction.getItemWithValue;

    Vue.prototype.$getAllValueAccrodingFinal = globalFunction.getAllValueAccrodingFinal;

    Vue.prototype.$transferParamFromObjToStr = globalFunction.transferParamFromObjToStr;
    
    globalPlugins.install(Vue);
  }
}