/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 音乐人入驻申请
export const Sup_musicianApply = (param) => Axios('POST', '/web/supplier/musicianApply', param);

// 音乐团体入驻申请
export const Sup_teamApply = (param) => Axios('POST', '/web/supplier/teamApply', param);

// 音乐公司入驻申请
export const Sup_companyApply = (param) => Axios('POST', '/web/supplier/companyApply', param);

// 供应商详情
export const Sup_info = (param) => Axios('POST', '/web/supplier/info', param);