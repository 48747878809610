const Member = () => import('@/pages/member/member.vue');//个人中心
const Member_v2 = () => import('@/pages/member/member_v2.vue');//个人中心
export default [{
        path: '/member',
        name: 'Member',
        component: Member,
        meta: {
            isLogin: true
        },
    }, 
    {
        path: '/member_v2',
        name: 'Member_v2',
        component: Member_v2,
        meta: {
            isLogin: true
        },
    }, 
]