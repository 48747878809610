export const valueLang = {
    en: {
        validate: {
            message1: 'Cannot be empty',
            message2: 'Please select',
            message3: 'Please upload',
            message4: 'Please input the correct mobile phone number',
            message5: 'Mailbox format must contain @',
            message6: 'Please input the correct format of mobile phone number or email',
            message7: 'The number must be greater than 0',
            message8: 'Only Chinese, English and numbers are supported',
            message9: 'Only English letters and numbers are supported',
            message10: 'Only Chinese and English alphanumeric spaces are supported',
            message11: 'The length needs to be 8-20 bits',
            message12: 'Same as the password you typed for the first time',
        },
        options: {
            select: 'Please select',
            OpenAndCloseStatus: ['Disable', 'Enable'],
            ApplyOptions: ['Applying', 'Processed'],
            SellOperateOptions: ['Waiting', 'Online', 'Removed'],
            SellStatusOptions: ['Waiting', 'Online', 'Removed'],
            VerifyStatusOptions: ['Waiting for review', 'Audit completed'],
            VerifyResultOptions: ['Reject', 'Succeed'],
            CheckStatusOptions: ['Under review', 'Rejected', 'Passed'],
            MarkResultOptions: ['Reject', 'Succeed'],
            SexOptions: ['', 'Male', 'Female'],
            CertificateType: ['', 'ID card', 'passports', 'business license'],
            AuthTypeOptions: ['', 'Exclusive license', 'Non-exclusive license'],
            SignStatusOptions: ['Unsigned', 'Signed', 'Expired'],
            AuthTimeOptions: ['Project one year', 'Project permanent'],
            AuthAreaOfMusicOptions: ['China', 'Global'],
            IsOrNotOptions: ['No', 'Yes'],
            ReceiptHeaderTypeOptions: ['Corporate unit','Individual/non-corporate unit'],
            ReceiptTypeOptions: ['Paper ordinary VAT invoice','Paper special VAT invoice','Electronic ordinary VAT invoice','Electronic  Invoice (Plain Invoice)','Electronic  Invoice (Special Invoice for Value-added Tax)'],
            ActiviateMusicAuthTypeOptions: ['Company','Individual'],
            TargetOfDeedsOptions: ['Music','Playlist'],
            MusicTypeOptions: ['BGM', 'Vocal','SFX'],
            MusicEffectOptions: ['Action sound','Ambient sound','Human voice','White noise'],
            DrawingStatusOptions: ['In review','Pending','Paid','Canceled'],
            DidPayOrderOptions: ['Unauthorized','Authorized'],
            UndoPayOrderOptions: ['Pending','Error','Overtime','Drop'],
            FilterMusicOptions: ['Recommended','Hot'],
            FilterSonglistOptions: [ 'Hot','Latest'],
            SortOptions: ['', 'Speed from fast to slow','Speed from slow to fast','Time from short to long','Time from long to short','Latest on shelves'],
            BusinessSceneOptions: ['Full scene','serial series/variety show/animation','commercial advertisement/promotional film','self-media video','movie','game/built-in music','documentary','online class/live broadcast', 'Offline public broadcast','Personal use (non-commercial)','Radio drama/audio books'],
            PayWaysOptions: ['Alipay payment','WeChat payment','public-to-public payment'],
            SettleWays: ['Alipay', 'PayPal', 'Payoneer', 'public-to-public payment'],
            LinksOptions: ['Personal website','Xia Mi Music', 'AudioJungle', 'Douban Music', 'Bandcamp', 'Spotify', 'SoundCloud', 'Apple-Music', 'Tencent Music','NetEase Cloud Music']
        }
    },
    zh_CN: {
        validate: {
            message1: '不可为空',
            message2: '请选择',
            message3: '请上传',
            message4: '请输入正确格式的手机号',
            message5: '邮箱格式必须含@',
            message6: '请输入正确格式的手机号或邮箱',
            message7: '数字必须大于0',
            message8: '只支持中英文及数字',
            message9: '只支持英文字母及数字',
            message10: '只支持汉字字母数字下划线空格',
            message11: '长度需要在8-20位',
            message12: '与首次键入的密码一致'
        },
        options: {
            select: '请选择',
            OpenAndCloseStatus: ['停用', '启用'],
            ApplyOptions: ['申请中', '已处理'],
            SellOperateOptions: ['待上架', '上架', '下架'],
            SellStatusOptions: ['待上架', '上架', '下架'],
            VerifyStatusOptions: ['待审核', '审核完成'],
            VerifyResultOptions: ['拒绝', '成功'],
            CheckStatusOptions: ['审核中', '已拒绝', '已通过'],
            MarkResultOptions: ['拒绝', '通过'],
            SexOptions: ['', '男', '女'],
            CertificateType: ['', '身份证', '护照', '营业执照'],
            AuthTypeOptions: ['', '独家授权', '非独家授权'],
            SignStatusOptions: ['未签约', '已签约', '已过期'],
            AuthTimeOptions: ['项目一年', '项目永久'],
            AuthAreaOfMusicOptions: ['中国', '全球'],
            IsOrNotOptions: ['否', '是'],
            ReceiptHeaderTypeOptions: ['企业单位', '个人/非企业单位'],
            ReceiptTypeOptions: ['纸质版增值税普通发票', '纸质版增值税专用发票', '电子版增值税普通发票','数电普票','数电专票'],
            ActiviateMusicAuthTypeOptions: ['公司', '个人'],
            TargetOfDeedsOptions: ['音乐', '歌单'],
            MusicTypeOptions: ['BGM', '人声', '音效'],
            MusicEffectOptions: ['动作音', '环境音', '人声', '白噪音'],
            DrawingStatusOptions: ['待审核', '待转账', '已到账', '已取消'],
            DidPayOrderOptions: ['未授权', '已授权'],
            UndoPayOrderOptions: ['待支付', '支付失败', '支付超时', '已下架'],
            FilterMusicOptions: ['推荐', '热门'],
            FilterSonglistOptions: [ '热门','最新'],
            SortOptions: ['', '速度从快到慢', '速度从慢到快', '时间从短到长', '时间从长到短', '最新上架'],
            BusinessSceneOptions: ['全场景', '连续剧/综艺/动画片', '商业广告/宣传片', '自媒体视频', '电影', '游戏/内置音乐', '纪录片', '网课/直播', '线下公播', '个人用途(非商用)', '广播剧/有声读物'],
            PayWaysOptions: ['支付宝付款', '微信付款', '公对公付款'],
            SettleWays: ['支付宝', 'PayPal', 'Payoneer', '公对公收款'],
            LinksOptions: ['个人网站', '虾米音乐', 'AudioJungle', '豆瓣音乐', 'Bandcamp', 'Spotify', 'SoundCloud', 'Apple-Music', '腾讯音乐', '网易云音乐']
        }
    }
}
